export const SENSOR_LIST_ACTIONS = {
  SENSOR_LIST_REQUESTED: 'SENSOR_LIST_REQUESTED',
  SENSOR_LIST_RESOLVED: 'SENSOR_LIST_RESOLVED',
  SENSOR_LIST_REJECTED: 'SENSOR_LIST_REJECTED',
  SENSOR_LIST_CLEAR: 'SENSOR_LIST_CLEAR',
  SENSOR_LIST_NEXT_PAGE_REQUESTED: 'SENSOR_LIST_NEXT_PAGE_REQUESTED',
  SENSOR_LIST_NEXT_PAGE_RESOLVED: 'SENSOR_LIST_NEXT_PAGE_RESOLVED',
  SENSOR_LIST_NEXT_PAGE_REJECTED: 'SENSOR_LIST_NEXT_PAGE_REJECTED',
};

export const requestSensorList = (mute=false) => ({
  type: SENSOR_LIST_ACTIONS.SENSOR_LIST_REQUESTED,
  mute,
});


export const resolveSensorList = (sensors, sensor_types, hasMore,mute=false) => ({
  type: SENSOR_LIST_ACTIONS.SENSOR_LIST_RESOLVED,
  sensors,
  sensor_types,
  hasMore,
  mute,
});

export const rejectSensorList = (error) => {
  return {
    type: SENSOR_LIST_ACTIONS.SENSOR_LIST_REJECTED,
    error,
  };
};

export const clearSensorList = () => {
  return {
    type: SENSOR_LIST_ACTIONS.SENSOR_LIST_CLEAR,
  }
}

export const requestNextPage = (page_url,mute=false) => ({
  type: SENSOR_LIST_ACTIONS.SENSOR_LIST_NEXT_PAGE_REQUESTED,
  page_url,
  mute
});

export const resolveNextPage = (sensors, hasMore,mute =false) => ({
  type: SENSOR_LIST_ACTIONS.SENSOR_LIST_NEXT_PAGE_RESOLVED,
  sensors,
  hasMore,
  mute,
});

export const rejectNextPage = (error) => ({
  type: SENSOR_LIST_ACTIONS.SENSOR_LIST_NEXT_PAGE_REJECTED,
  error,
})

export const SET_CONNECTIVITY_FILTER = 'SET_CONNECTIVITY_FILTER';
export const CONNECTIVITY_FILTERS = {
  ALL: 'All',
  ONLINE: 'Online',
  OFFLINE: 'Offline',
};

export const setConnectivityFilter = (name) => {
  return {
    type: SET_CONNECTIVITY_FILTER,
    name: name,
  };
};


export const SET_BATTERY_FILTER = 'SET_BATTERY_FILTER';
export const BATTERY_FILTERS = {
  ALL: 'All',
  BATTERY_OK: 'Battery ok',
  BATTERY_LOW: 'Battery low',
};

export const setBatteryFilter = (name) => {
  return {
    type: SET_BATTERY_FILTER,
    name: name,
  };
};

export const SET_ALARM_FILTER = 'SET_ALARM_FILTER';
export const ALARM_FILTERS = {
  ALL: 'All',
  HAS_ALARM: 'Has alarm',
  NO_ALARM: 'No alarm',
};

export const setAlarmFilter = (name) => {
  return {
    type: SET_ALARM_FILTER,
    name: name,
  };
};

export const SET_TYPE_FILTER = 'SET_TYPE_FILTER';
export const TYPE_FILTER_ALL = 'All';

export const setTypeFilter = (name) => {
  return {
    type: SET_TYPE_FILTER,
    name: name,
  };
};

export const SET_SEARCH_FILTER = 'SET_SEARCH_FILTER';

export const setSearchFilter = (pattern) => {
  return {
    type: SET_SEARCH_FILTER,
    pattern: pattern,
  };
};

export const SENSOR_SELECTED = 'SENSOR_SELECTED';

export const setSensorSelected = (sensorRef) => {
  return {
    type: SENSOR_SELECTED,
    sensorRef,
  };
};

export const CLEAR_SELECTED_SENSOR = 'CLEAR_SELECTED_SENSOR';

export const clearSelectedSensor = () => {
  return {
    type: CLEAR_SELECTED_SENSOR,
  };
};

export const SENSOR_CONFIG_UPDATED = 'SENSOR_CONFIG_UPDATED';

export const updateSensorConfig = (sensorRef, newConfiguration) => {
  return {
    type: SENSOR_CONFIG_UPDATED,
    sensorRef,
    newConfiguration
  };
};

export const SENSOR_NOTIFICATIONS_UPDATED = 'SENSOR_NOTIFICATIONS_UPDATED';

export const updateSensorNotifications = (sensorRef, newNotificationSettings) =>{
  return {
    type: SENSOR_NOTIFICATIONS_UPDATED,
    sensorRef,
    newNotificationSettings,
  }
}


export const SENSOR_NAME_UPDATED = 'SENSOR_NAME_UPDATED';

export const updateSensorName = (sensorRef, newName) => {
  return {
    type: SENSOR_NAME_UPDATED,
    sensorRef,
    newName
  };
};

export const MEASUREMENT_CONFIG_UPDATED = 'MEASUREMENT_CONFIG_UPDATED';

export const updateMeasurementConfig = (sensorRef, configRef, newConfig) => {
  return {
    type: MEASUREMENT_CONFIG_UPDATED,
    sensorRef,
    configRef,
    newConfig
  };
};


