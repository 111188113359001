import React, {useEffect} from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import Grid from "@material-ui/core/Grid";
import Constants from '../../../common/constants';
import "./DiscomfortIcon.css";
import DiscomfortIcon from './DiscomfortIcon';
import { makeStyles } from '@material-ui/core';
import {useTranslation} from 'react-i18next'
const useStyles = makeStyles(theme=>({
  iconAlignment:{
    paddingBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));
const DiscomfortIndexGauge = ({gauge_info, height, columns, index}) => {
  const gaugeId = 'digauge' + index;
  const {t}= useTranslation();
  const classes = useStyles();

  const clampValue = (value) => {
    if (value < -10)
      return -10;
    else if (value > 57)
      return 57;
    else
      return value;
  };

  const createBand = (axis, start, end, color) => {
    let range = axis.axisRanges.create();
    range.value = start;
    range.endValue = end;
    range.axisFill.fillOpacity = 1;
    range.axisFill.fill = am4core.color(color);
    range.axisFill.zIndex = -1;
    range.axisFill.interactionsEnabled = true;
    range.axisFill.isMeasured = true;
  }
  const value = clampValue(gauge_info.value);
  useEffect(() => {

    // Create chart
    let chart = am4core.create(gaugeId, am4charts.GaugeChart);
    chart.innerRadius = -30;

    // Create axis
    let axis = chart.xAxes.push(new am4charts.ValueAxis());
    axis.min = -10;
    axis.max = 57;
    axis.strictMinMax = true;
    axis.renderer.minGridDistance = 5000;

    // Add the color bands
    createBand(axis, -10, -1, Constants.MapDIColors["Very Cold"]);
    createBand(axis, -1, 13, Constants.MapDIColors["Cold"]);
    createBand(axis, 13, 15, Constants.MapDIColors["Cool"]);
    createBand(axis, 15, 27, Constants.MapDIColors["OK"]);
    createBand(axis, 27, 33, Constants.MapDIColors["Caution"]);
    createBand(axis, 33, 40, Constants.MapDIColors["Extreme Caution"]);
    createBand(axis, 40, 51, Constants.MapDIColors["Danger"]);
    createBand(axis, 51, 57, Constants.MapDIColors["Extreme Danger"]);

    // The value hand
    let hand = chart.hands.push(new am4charts.ClockHand());
    hand.value = value;
    hand.pin.disabled = true;
    hand.innerRadius = am4core.percent(20);
    hand.startWidth = 15;

    // Add chart title
    let title = chart.titles.create();
    title.text = t(gauge_info.name);
    title.fontSize = "1.25rem";
    title.fontFamily = "Roboto";
    title.fontWeight = "500";
    title.marginBottom = 20;
    
    // Add bottom label
    let label = chart.chartContainer.createChild(am4core.Label);
    label.text = t(Constants.getDIDescription(value));
    label.fontSize = 50;
    label.align = "center";

    return () => chart.dispose();
  }, [gauge_info, gaugeId,value ,t ]);

  const divStyle = {
    height: `${height}px`,
  };

  return <Grid item xs={columns}>
    <div id={gaugeId} style={divStyle}/>
    {<div className={classes.iconAlignment}><DiscomfortIcon value={value}/></div>}
  </Grid>;
}

export default DiscomfortIndexGauge;
