
const Constants = {
  DISCOMFORT_INDEX_INTERNAL : 'S2W-SI-DI-W-SHT21',
  DISCOMFORT_INDEX_EXTERNAL : 'S2W-SE-DI-W-SHT21',
  
  DISCOMFORT_INDEX_MEASUREMENT_TYPE: 'discomfort',
  DISCOMFORT_INDEX_SENSOR_TYPE: 'discomfort',
  DISCOMFORT_INDEX_VALUE_TYPE: 'disc_index',
  BOOL_VALUE_TYPE: 'boolean',
  FLOAT_VALUE_TYPE: 'float',
  MIN_DI: -50,
  MAX_DI: 100,
  DI_DESCRIPTIONS: {
    VERY_COLD: 'Very Cold',
    COLD: 'Cold',
    COOL: 'Cool',
    OK: 'OK',
    CAUTION: 'Caution',
    EXTREME_CAUTION: 'Extreme Caution',
    DANGER: 'Danger',
    EXTREME_DANGER: 'Extreme Danger',
  },

  MapDIColors:{
    'Very Cold': '#2c5dbf',
    'Cold': '#4e8ad9',
    'Cool': '#8db9f2',
    'OK': '#00af00',
    'Caution': '#dfdf40',
    'Extreme Caution': '#ffb500',
    'Danger':'#ff7c00',
    'Extreme Danger':'#ff1c00'    
  },

  getSensorType: (type) =>{
    if(type === Constants.DISCOMFORT_INDEX_EXTERNAL || type === Constants.DISCOMFORT_INDEX_INTERNAL){
      return Constants.DISCOMFORT_INDEX_SENSOR_TYPE;
    }
    return type;
  },
  
  getDIDescription: (value) => {
    if (value < -1)
      return Constants.DI_DESCRIPTIONS.VERY_COLD;
    if (value < 13)
      return Constants.DI_DESCRIPTIONS.COLD;
    if (value < 15)
      return Constants.DI_DESCRIPTIONS.COOL;
    if (value < 27)
      return Constants.DI_DESCRIPTIONS.OK;
    else if (value < 33)
      return Constants.DI_DESCRIPTIONS.CAUTION;
    else if (value < 40)
      return Constants.DI_DESCRIPTIONS.EXTREME_CAUTION;
    else if (value < 51)
      return Constants.DI_DESCRIPTIONS.DANGER;
    else if (value >= 51)
      return Constants.DI_DESCRIPTIONS.EXTREME_DANGER;
    else
      return '';
  },

};

export const clearValuesOfDI = (sensor)=>{
  const discomfort_latest_value = sensor.latest_values.filter(x => x.measurement_type === Constants.DISCOMFORT_INDEX_SENSOR_TYPE)
  
  const discomfort_measurement_config = sensor.measurement_configs.filter(x => x.measurement_type === Constants.DISCOMFORT_INDEX_SENSOR_TYPE)

  const discomfort_current_alarms = sensor.current_alarms.filter(x => x.measurement_type === Constants.DISCOMFORT_INDEX_SENSOR_TYPE)

  sensor.latest_values = discomfort_latest_value;
  sensor.measurement_configs = discomfort_measurement_config;
  sensor.current_alarms = discomfort_current_alarms;

  return sensor;
}

export const HighAlarmDIMapping = {
  'No Alarm': -50,
  [Constants.DI_DESCRIPTIONS.VERY_COLD]: -1,
  [Constants.DI_DESCRIPTIONS.COLD]: 13,
  [Constants.DI_DESCRIPTIONS.COOL]: 15,
  [Constants.DI_DESCRIPTIONS.OK]: 27,
  [Constants.DI_DESCRIPTIONS.CAUTION]: 33,
  [Constants.DI_DESCRIPTIONS.EXTREME_CAUTION]: 40,
  [Constants.DI_DESCRIPTIONS.DANGER]: 51,
  [Constants.DI_DESCRIPTIONS.EXTREME_DANGER]: 100,
};

export const LowAlarmDIMapping = {
  [Constants.DI_DESCRIPTIONS.VERY_COLD]: -50,
  [Constants.DI_DESCRIPTIONS.COLD]: -1,
  [Constants.DI_DESCRIPTIONS.COOL]: 13,
  [Constants.DI_DESCRIPTIONS.OK]: 15,
  [Constants.DI_DESCRIPTIONS.CAUTION]: 27,
  [Constants.DI_DESCRIPTIONS.EXTREME_CAUTION]: 33,
  [Constants.DI_DESCRIPTIONS.DANGER]: 40,
  [Constants.DI_DESCRIPTIONS.EXTREME_DANGER]: 51,
  'No Alarm': 100,
};

export default Constants;
