import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from "@material-ui/core/Tooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const SignalStrengthIcon = ({ rssi }) => {
  const iconClass = (rssi) => {
    if (rssi === null) return '';
    if (rssi > -56) return 'wifi';
    else if (rssi > -70) return 'wifi-2';
    else if (rssi > -78) return 'wifi-1';
    else return 'wifi-slash';
  };

  if (rssi)
    return (
      <Tooltip title={rssi} placement="top">
        <span>
          <FontAwesomeIcon icon={["far", iconClass(rssi)]} />
        </span>
      </Tooltip>
    )
  return null;
};

SignalStrengthIcon.propTypes = {
  rssi: PropTypes.number,
};

export default SignalStrengthIcon;