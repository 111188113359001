import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';


const ConditionalRoute = ({
  component,
  routeCondition,
  redirectTo,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => (routeCondition
        ? React.createElement(component, {...props})
        : <Redirect to={{ pathname: redirectTo, state: { from: rest.location } }} />
      )}
    />
  )
};

export default ConditionalRoute;
