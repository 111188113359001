import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import DashboardPanels from "../components/DashboardPanels";
import { useDispatch, useSelector } from "react-redux";
import { dashboardSelector } from "../selectors/dashboardSelectors";
import moment from "moment";
import { requestDashboard } from "../actions/dashboardActions";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { requestLogout, clearDashboard, clearSensorList, clearSelectedSensor } from "../actions";
import { selectedSensor } from '../selectors/sensorSelectors';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2)
  }
}));

const AdapterLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);

const Dashboard = () => {
  const classes = useStyles();

  const {t} = useTranslation();
  const { sensors, online, alarm, battery_low, error, lastResolved } = useSelector(dashboardSelector);
  const selectedS = useSelector(selectedSensor);
  const dispatch = useDispatch();
  const shouldFetch = lastResolved === null || moment().subtract(15, 'minutes').isAfter(lastResolved);

  useEffect(() => {
    if (selectedS !== null) {
      dispatch(clearSelectedSensor());
    }
    if (shouldFetch)
      dispatch(requestDashboard());
  }, [selectedS,dispatch,shouldFetch]
  );

  const handleLogout = (event) => {
    dispatch(clearDashboard());
    dispatch(clearSensorList());
    dispatch(requestLogout());
  }

  return (
    <>
      <Typography variant="h4" gutterBottom component="h2">
        {t("Dashboard")}
      </Typography>
      <DashboardPanels sensors={sensors} alarm={alarm} online={online} battery_low={battery_low} error={error} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <Typography variant="h5" gutterBottom>{t("Quick links")}</Typography>
            <List>
              <ListItem button component={AdapterLink} to="/sensors">
                <ListItemText primary={t("My Sensors")} />
              </ListItem>
              <ListItem button onClick={handleLogout}>
                <ListItemText primary={t("Logout")} />
              </ListItem>
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <Typography variant="h5" gutterBottom>{t("Latest news")}</Typography>
            <List>
              <ListItem divider>
                <ListItemText primary="The new web interface of S2W.io has launched" />
                <ListItemSecondaryAction>15/09/2019</ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemText primary="Elbis is an exhibitor in Thessaloniki International Fair 2019" />
                <ListItemSecondaryAction>07/09/2019</ListItemSecondaryAction>
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default Dashboard;
