import { call, put, takeLatest, select } from 'redux-saga/effects';

import * as actions from '../actions';
import { measurementsService } from '../services';
import { jwtTokenSelector } from '../selectors/userSelectors';
import { selectedSensor } from '../selectors/sensorSelectors';
import {filter, keys, find} from 'lodash';
import Constants from '../common/constants'

function filterDIData(measurements){

  const measurement_names = filter(keys(measurements[0]), x => x !== 'time');

  if(find(measurement_names, x => x === Constants.DISCOMFORT_INDEX_MEASUREMENT_TYPE)){
    let filtered_measurements;  
    filtered_measurements = measurements.map((meas)=>{
      return({time: meas.time, discomfort: meas.discomfort})
    })

    return filtered_measurements;
  }
  return measurements;
}

export function* measurements(action) {
  try {
    const jwtToken = yield select(jwtTokenSelector);
    const sensorRef = yield select(selectedSensor);
    const data = yield call(measurementsService.getMeasurements, sensorRef.ref_id, action.startDate, action.endDate, jwtToken);
    const hasMore = data.next != null;

    yield put(actions.resolveMeasurements(filterDIData(data.results), hasMore));

    if (hasMore) {
      yield put(actions.requestNextMeasurements(data.next))
    };

  } catch (e) {
    yield put(actions.rejectMeasurements(e));
    if (e.result && e.result === "unauthorized")
      yield put(actions.tokenExpired());
  }
}

export function* nextMeasurements(action) {
  try {
    const jwtToken = yield select(jwtTokenSelector);
    const data = yield call(measurementsService.getNextMeasurements, action.nextURL, jwtToken);
    const hasMore = data.next != null;
    yield put(actions.resolveNextMeasurements(filterDIData(data.results), hasMore));

    if (hasMore) {
      yield put(actions.requestNextMeasurements(data.next));
    };
  } catch (e) {
    yield put(actions.rejectNextMeasurements(e));
    if (e.result && e.result === "unauthorized") {
      yield put(actions.tokenExpired());
    }
  }
}

export default function* measurementsSaga() {
  // takeLatest retrieves the latest action that matches the first argument
  // (cancels all other pending) and creates a saga given by the second argument
  // we can choose to pass more args which will be passed to the saga in addition
  // to the incoming action
  yield takeLatest(actions.MEASUREMENTS_ACTIONS.MEASUREMENTS_REQUESTED, measurements);
  yield takeLatest(actions.MEASUREMENTS_ACTIONS.MEASUREMENTS_NEXT_PAGE_REQUESTED, nextMeasurements);
}
