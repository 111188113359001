import React from 'react'
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router'
import BaseLayout from './layout/BaseLayout';
import SignIn from './pages/LoginPage';
import ScrollToTop from './components/ScrollToTop';
import {useSelector} from 'react-redux';
import { history } from "./storeConfig";
import ConditionalRoute from './components/PrivateRoute';
import RegisterPage from "./pages/RegisterPage";
import PasswordResetPage from './pages/PasswordResetPage';

export default () => {
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);

  return (
    <ConnectedRouter history={history}>
      <ScrollToTop>
        <Switch>
          <Route exact path='/login' component={ SignIn }/>
          <Route exact path='/register' component={ RegisterPage }/>
          <Route exact path='/passwordreset' component = {PasswordResetPage}/>
          {/* <Route exact path='/dashboard' component={ Dashboard }/> */}
          <ConditionalRoute path='/' component={ BaseLayout } routeCondition={isAuthenticated} redirectTo='/login'>
          </ConditionalRoute>

        </Switch>
      </ScrollToTop>
    </ConnectedRouter >
  )};
