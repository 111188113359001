import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from './locales/en/translation.json';
import translationEL from './locales/el/translation.json';
import translationIT from './locales/it/translation.json';
import translationTR from './locales/tr/translation.json';
import translationDE from './locales/de/translation.json';
import translationRU from './locales/ru/translation.json';
import translationES from './locales/es/translation.json';
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: translationEN,
  },
  el: {
    translation: translationEL,
  },
  it: {
    translation: translationIT,
  },
  ru:{
    translation: translationRU,
  },
  de:{
    translation: translationDE,
  },
  tr: {
    translation: translationTR,
  },
  es: {
	  translation : translationES
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;