import React from 'react';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Link from '@material-ui/core/Link';
import {Link as RouterLink} from 'react-router-dom';
import Typography from "@material-ui/core/Typography";
import {useTranslation} from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectedSensor } from '../selectors/sensorSelectors';

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(3)
  },
}));

const staticBreadcrumps = {
  '/sensors': 'Sensors',
}

const breadcrumbName = (name,t) => {
  if (name in staticBreadcrumps) return t(staticBreadcrumps[name]);

  const sensorId = /\/sensors\/([^/]*)\/?$/.exec(name)
  if (sensorId !== null) return sensorId[1];

  if (name.endsWith('/edit')) return t("Edit");

  if (name.endsWith('/measurements')) return t("Measurements");

  if (name.endsWith('/events')) return t("Events");

  if (name.endsWith('/settings')) return t("Settings");
};

const LinkRouter = props => <Link {...props} component={RouterLink}/>;

const TopBreadcrumbs = ({location}) => {
  const classes = useStyles();
  const sensor = useSelector(selectedSensor);

  const {t} = useTranslation();

  let pathnames = location.pathname.split('/').filter(x => x);
  let links = location.pathname.split('/').filter(x => x);
  
  if(sensor){
    if(pathnames.length > 1){
      if(pathnames[1] === sensor.ref_id){
        pathnames[1] = sensor.name;
      }
    }
  }


  return <div className={classes.root}>
    <Breadcrumbs aria-label="breadcrumb">
      <LinkRouter color="inherit" to="/">
        {t("Home")}
      </LinkRouter>
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const breadcrump_to = `/${pathnames.slice(0, index + 1).join('/')}`;
        const to = `/${links.slice(0, index + 1).join('/')}`;

        return last ? (
          <Typography color="textPrimary" key={to}>
            {breadcrumbName(breadcrump_to,t)}
          </Typography>
        ) : (
          <LinkRouter color="inherit" to={to} key={to}>
            {breadcrumbName(breadcrump_to,t)}
          </LinkRouter>
        );
      })}
    </Breadcrumbs>
  </div>
};

export default TopBreadcrumbs;
