import React, {useEffect} from 'react';

import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4charts from "@amcharts/amcharts4/charts";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from 'react-i18next';
am4core.useTheme(am4themes_animated);

const Gauge = ({gauge_info, height, columns, index}) => {
  const gaugeId = 'gauge' + index;

  const {t} = useTranslation();

  useEffect(() => {
    const checkValidValue = gauge_info.gauge_low <= gauge_info.value && gauge_info.value <= gauge_info.gauge_high;

    // Create chart
    let chart = am4core.create(gaugeId, am4charts.GaugeChart);
    chart.innerRadius = -10;

    // Create axis
    let axis = chart.xAxes.push(new am4charts.ValueAxis());
    axis.min = gauge_info.gauge_low;
    axis.max = gauge_info.gauge_high;
    axis.strictMinMax = true;
    axis.renderer.minGridDistance = 50;

    // Add the alarm bands
    let range = axis.axisRanges.create();
    range.value = gauge_info.gauge_low;
    range.endValue = gauge_info.alarm_low;
    range.axisFill.fillOpacity = 1;
    range.axisFill.fill = am4core.color("#ea3838");
    range.axisFill.zIndex = -1;

    // Add the alarm bands
    let range2 = axis.axisRanges.create();
    range2.value = gauge_info.alarm_high;
    range2.endValue = gauge_info.gauge_high;
    range2.axisFill.fillOpacity = 1;
    range2.axisFill.fill = am4core.color("#ea3838");
    range2.axisFill.zIndex = -1;

    // Add the ok band
    let range3 = axis.axisRanges.create();
    range3.value = gauge_info.alarm_low;
    range3.endValue = gauge_info.alarm_high;
    range3.axisFill.fillOpacity = 1;
    range3.axisFill.fill = am4core.color("#00CC00");
    range3.axisFill.zIndex = -1;

    if (checkValidValue) {
      // The value hand
      let hand = chart.hands.push(new am4charts.ClockHand());
      hand.value = gauge_info.value;
      hand.pin.disabled = true;
      hand.innerRadius = am4core.percent(20);
      hand.startWidth = 15;
    }

    // Add chart title
    let title = chart.titles.create();
    title.text = t(gauge_info.name);
    title.fontSize = "1.25rem";
    title.fontFamily = "Roboto";
    title.fontWeight = "500";
    title.marginBottom = 20;

    // Add bottom label
    let label = chart.chartContainer.createChild(am4core.Label);

    label.text = checkValidValue ? `${gauge_info.value} ${gauge_info.units}` : t('Value out of boundaries');
    label.fontSize = 50;

    label.align = "center";


    return () => chart.dispose();
  }, [gauge_info, gaugeId,t]);

  const divStyle = {
    height: `${height}px`,
  };

  return <Grid item xs={columns}>
    <div id={gaugeId} style={divStyle}/>
  </Grid>;
};

export default Gauge;
