import React, { useState } from 'react'
import { IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import {requestRecipientDelete} from '../../../actions';
import { jwtTokenSelector } from '../../../selectors/userSelectors';
import ConfirmDialog from '../../../common/ConfirmDialog';
import { useTranslation } from 'react-i18next';

const RemoveRecipient = ({recipientID,emailAddress})=>{
  const dispatch = useDispatch();
  const [askConfirmation,setAskConfirmation] = useState(false);
  const jwtToken = useSelector(jwtTokenSelector);
  const {t} = useTranslation();

  const toggleConfirmation = ()=>{
    setAskConfirmation(true);
  }

  const fnAgree =()=>{
    dispatch(requestRecipientDelete(recipientID,jwtToken));
    setAskConfirmation(false);
  }

  const fnReject = () =>{
    setAskConfirmation(false);
  }

  return <>
  {askConfirmation && <ConfirmDialog open={askConfirmation} title={t("Email removal")} text={t("Are you sure you want to remove this email") + emailAddress+" ?"} fnAgree={fnAgree} fnReject={fnReject} />}
  <IconButton onClick= {toggleConfirmation}>
    <FontAwesomeIcon size="sm" icon={["far","trash"]} />
  </IconButton>
  </>
}

export default RemoveRecipient;