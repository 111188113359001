import React, { useEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { useSelector } from 'react-redux';
import { showAlarmEventsSelector, showUserEventsSelector, showConnectivityEventsSelector, showChipStateEventsSelector } from '../../../selectors/eventChartSelector';
import { eventsSelector } from '../../../selectors/eventsSelectors';
import {useTranslation} from 'react-i18next';
import {eventsMap,alarmRegex,lastWordRegex} from '../../../alarmDictionary'
import "./chartStyle.css"

const interfaceColors = new am4core.InterfaceColorSet();

const Chart = ({ measurements, measurement_names }) => {
  const height = 50 + measurement_names.length * 200;
  const {t} = useTranslation();

  const showUserEvents = useSelector(showUserEventsSelector);
  const showAlarmEvents = useSelector(showAlarmEventsSelector);
  const showConnectivityEvents = useSelector(showConnectivityEventsSelector);
  const events = useSelector(eventsSelector);
  const showChipStateEvents = useSelector(showChipStateEventsSelector);

  const processEvents = (events) => {

    let alarmEvents = [];
    let userEvents = [];
    let connectivityEvents = [];
    let chipStateEvents = [];
    let online = true;
    let cs = 0;
    events.forEach((event) => {
      eventsMap[alarmRegex.exec(event.event_type)] === 'Alarm' && alarmEvents.push(event);
      eventsMap[event.event_type] === 'User' && userEvents.push(event);

      if (online) {
        if (eventsMap[event.event_type] === 'Connectivity_off') {
          online = false;
          connectivityEvents.push(event);
        }
      } else {
        if (eventsMap[event.event_type] === 'Connectivity_on') {
          online = true;
          connectivityEvents.push(event);
        }
      }

      if(cs === 0) {
        if(eventsMap[event.event_type] === 'Chip_state_problem') {
          cs = 1;
          event.cs = 1;
          chipStateEvents.push(event);

        }
      } else {
        if(eventsMap[event.event_type] === 'Chip_state_restored') {
          cs = 0;
          event.cs = 0;
          chipStateEvents.push(event);
        }
      }
    });

    
    let on_off = [];
    let chipStateFaultRestore = [];
    let ev = {};
    for (let i = 0; i < connectivityEvents.length; i += 2) {
      ev.startTime = connectivityEvents[i].time;
      ev.endTime = connectivityEvents[i + 1] ? connectivityEvents[i + 1].time : new Date();
      ev.type = connectivityEvents[i].event_type;
      on_off.push(ev);
      ev = {};
    };
    
    for(let i=0;i< chipStateEvents.length; i += 2) {
      ev.startTime = chipStateEvents[i].time;
      ev.endTime = chipStateEvents[i + 1] ? chipStateEvents[i + 1].time : new Date();
      ev.type = chipStateEvents[i].event_type;
      chipStateFaultRestore.push(ev);
      ev = {};
    }

    return [alarmEvents, userEvents, connectivityEvents, on_off, chipStateEvents, chipStateFaultRestore];
  }

  useEffect(() => {
    let chart = am4core.create("chartDiv", am4charts.XYChart);
    chart.data = measurements;
    chart.leftAxesContainer.layout = "vertical"

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.ticks.template.length = 8;
    dateAxis.renderer.ticks.template.strokeOpacity = 0.1;
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.ticks.template.disabled = false;
    dateAxis.renderer.ticks.template.strokeOpacity = 0.2;

    // Add scrollbar
    chart.scrollbarX = new am4core.Scrollbar();

    measurement_names.forEach((name, idx) => {
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.marginTop = 30;
      valueAxis.title.text = name;

      if (idx % 2 === 1) {
        valueAxis.renderer.gridContainer.background.fill = interfaceColors.getFor("alternativeBackground");
        valueAxis.renderer.gridContainer.background.fillOpacity = 0.05;
        valueAxis.renderer.grid.template.stroke = interfaceColors.getFor("background");
        valueAxis.renderer.grid.template.strokeOpacity = 1;
      }

      // Create series
      let series = chart.series.push(new am4charts.LineSeries());
      series.yAxis = valueAxis;
      series.dataFields.valueY = name;
      series.dataFields.dateX = "time";
      series.strokeWidth = 2;
      series.tooltipText = "{valueY}";
      series.tooltip.pointerOrientation = "vertical";
      series.tooltip.background.cornerRadius = 20;
      series.tooltip.background.fillOpacity = 0.5;
      series.tooltip.label.padding(12, 12, 12, 12)
      series.minBulletDistance = 50;

      // Add the bullets
      series.bullets.push(new am4charts.CircleBullet());
      series.minBulletDistance = 10;
    });


    const displayEvent = (event, event_type) => {
      let range = dateAxis.axisRanges.create();
      range.label.text = t(event.event_type)

      range.date = new Date(event.time);
      range.grid.strokeDasharray = 2;
      range.grid.strokeWidth = 2;
      range.grid.strokeOpacity = 1;
      range.label.inside = true;
      range.label.rotation = 270
      range.label.verticalCenter = "bottom";
      range.label.valign = "middle"
      range.label.fontSize = "11";
      range.label.dx = 10;
      range.label.wrap = true;
      range.label.maxWidth = 150;
      if (event_type === 'user') {
        range.grid.stroke = am4core.color("#0000ff");
      } else if (event_type === 'alarm') {

        range.label.text = t(alarmRegex.exec(event.event_type))+" "+t(lastWordRegex.exec(event.event_type));
        range.label.userClassName = "alarm";
        range.grid.stroke = am4core.color("#ff0000");

      } else if (event_type === 'connection') {
        range.grid.stroke = am4core.color("#ff0000");
        range.label.text = "";
      } else if (event_type === 'chip_state') {
        const evt_colour = event.cs === 0 ? "#00ff00" : "#ff0000";
        range.label.text = t(event.event_type);
        range.grid.stroke = am4core.color(evt_colour);
        range.label.userClassName = event.cs === 0 ? "chipStateOk" : "chipStateDisconnected";
        
      }
      return range;
    }

    if (events) {
      let alarmEvents = [];
      let userEvents = [];
      let connectivityEvents = [];
      let chipStateEvents = [];

      let processedEvents = processEvents(events);
      alarmEvents = processedEvents[0];
      userEvents = processedEvents[1];
      connectivityEvents[0] = processedEvents[3];
      connectivityEvents[1] = processedEvents[2];
      chipStateEvents[0] = processedEvents[4];
      chipStateEvents[1] = processedEvents[5];

      showAlarmEvents && alarmEvents.forEach((event) => {
        displayEvent(event, 'alarm');
      });

      showUserEvents && userEvents.forEach((event) => {
        displayEvent(event, 'user');
      });

      showConnectivityEvents && connectivityEvents[1].forEach((event) => {
        displayEvent(event, 'connection');
      })

      showChipStateEvents && chipStateEvents[0].forEach( (event) => {
        displayEvent(event, 'chip_state');
      })

      showChipStateEvents && chipStateEvents[1].forEach((event) => {
        let range = dateAxis.axisRanges.create();
        range.date = new Date(event.startTime);
        range.endDate = new Date(event.endTime);
        range.axisFill.fillOpacity = 0.1;
        range.axisFill.fill = am4core.color("#ff0000");
        range.axisFill.tooltip = new am4core.Tooltip();
        range.axisFill.tooltipText = t(event.type);
        range.axisFill.interactionsEnabled = true;
        range.axisFill.isMeasured = true;
        range.axisFill.tooltipPosition = "pointer";
      })

      showConnectivityEvents && connectivityEvents[0].forEach((event) => {
        let range = dateAxis.axisRanges.create();
        range.date = new Date(event.startTime);
        range.endDate = new Date(event.endTime);
        range.axisFill.fillOpacity = 0.1;
        range.axisFill.fill = am4core.color("#ff0000");
        range.axisFill.tooltip = new am4core.Tooltip();
        range.axisFill.tooltipText = t(event.type);
        range.axisFill.interactionsEnabled = true;
        range.axisFill.isMeasured = true;
        range.axisFill.tooltipPosition = "pointer";
      })

    }

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;
    return () => {
      chart.dispose();
    }
  }, [measurements, measurement_names, events, showUserEvents, showConnectivityEvents, showAlarmEvents, showChipStateEvents, t]);

  const divStyle = { height: height, flex: "1 100%" };

  return <div style={divStyle} id="chartDiv" />;
};

export default Chart;
