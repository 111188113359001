import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const USBBatteryIcon = ({ battery, isUSB }) => {
  if (isUSB)
    return <FontAwesomeIcon icon={["fab", "usb"]}/>

  if (battery === null)
    return null

  // const iconClass = (battery) => {
  //   let value = Math.floor((Math.floor(battery) - 12.5) / 25) + 1;
  //   return `fa fa-battery-${value}`;
  // };

  return <FontAwesomeIcon icon={["far", "battery-full"]}/>
};

USBBatteryIcon.propTypes = {
  isUSB: PropTypes.bool,
  battery: PropTypes.number,
};

export default USBBatteryIcon;