import {
  createBrowserHistory
} from 'history'
import {
  applyMiddleware,
  createStore
} from 'redux'
import {
  routerMiddleware
} from 'connected-react-router'
import createSagaMiddleware from 'redux-saga';
import {
  composeWithDevTools
} from 'redux-devtools-extension';

import createRootReducer from './reducers'
import rootSaga from './sagas';

import {
  persistStore,
  persistReducer
} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native


export const history = createBrowserHistory();

// Build the middleware for asynchronous actions
const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['router', 'measurements', 'events', 'sensors'] // Blacklist the router so it gets the current page from the url
}

const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});

const middleware = [
  sagaMiddleware,
  routerMiddleware(history),
];

const persistedReducer = persistReducer(persistConfig, createRootReducer(history));

export default () => {
  let store = createStore(persistedReducer, /* preloadedState, */ composeEnhancers(
    applyMiddleware(...middleware),
    // other store enhancers if any
  ));
  let persistor = persistStore(store)

  // Run the saga middleware
  sagaMiddleware.run(rootSaga);

  return {store, persistor};
}
