import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import {Link as RouterLink} from "react-router-dom";
import {makeStyles} from "@material-ui/core";
import logo from './logo.png'
import {Field, Form, Formik} from "formik";
import Grid from "@material-ui/core/Grid";
import {TextField} from "formik-material-ui";
import FieldErrorRenderer from "../components/SensorEdit/FieldErrorRenderer";
import * as yup from 'yup';
import userService from "../services/userService";
import omit from 'lodash/omit';
import SnackbarContent from "@material-ui/core/SnackbarContent";
import green from "@material-ui/core/colors/green";
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  success: {
    backgroundColor: green[600],
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  check: {
    // marginTop: theme.spacing(1),
  },
  form: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  fieldError: {
    color: theme.palette.error.main
  },
  logo: {
    width: "50%",
    margin: theme.spacing(3)
  },
  root: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
  }
}));

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password1: yup.string().required("Password is required"),
  password2: yup.string().oneOf([yup.ref('password1')], 'Passwords must match').required("Password is required"),
  company_name: yup.string()
})

const RegisterPage = () => {
  const classes = useStyles();
  const [isCompany, setIsCompany] = useState(false);
  const handleCompanyClick = () => setIsCompany(!isCompany);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitErrors, setSubmitErrors] = useState(null);
  const {t} = useTranslation();

  const initialValues = {
    email: '',
    password1: '',
    password2: '',
    company_name: ''
  }

  const submitConfig = (values, actions) => {
    const postValues = values.company_name !== '' ? values : omit(values, ['company_name']);
    userService.register(postValues)
      .then(() => {
        setSubmitSuccess(true);
        setSubmitErrors(null);
        // actions.setSubmitting(false);
      })
      .catch((err) => {
          setSubmitSuccess(false);
          setSubmitErrors(err);
          actions.setSubmitting(false);
        }
      )
  }

  return (<Formik
      initialValues={initialValues}
      onSubmit={submitConfig}
      validationSchema={schema}
    >
      {({
          dirty,
          handleSubmit,
          isSubmitting,
        }) => (
        <main className={classes.main}>
          <CssBaseline/>
          <Paper className={classes.paper}>
            <img className={classes.logo} src={logo} alt="S2W logo"/>
            <Typography component="h1" variant="h5">
              {t('Register for an account')}
            </Typography>
            <Form>
              <Grid container spacing={3} className={classes.root}>
                <Grid item xs={12}>
                  <Field
                    type='text'
                    label={t('Email Address')}
                    name='email'
                    component={TextField}
                    fullWidth
                  />
                  {submitErrors &&
                  <FieldErrorRenderer field='email' details={submitErrors.details} className={classes.fieldError}/>}
                </Grid>
                <Grid item xs={12}>
                  <Field
                    type="password"
                    label={t('Password')}
                    name='password1'
                    component={TextField}
                    fullWidth
                  />
                  {submitErrors &&
                  <FieldErrorRenderer field='password1' details={submitErrors.details} className={classes.fieldError}/>}
                </Grid>
                <Grid item xs={12}>
                  <Field
                    type="password"
                    label={t('Password')}
                    name='password2'
                    component={TextField}
                    fullWidth
                  />
                  {submitErrors &&
                  <FieldErrorRenderer field='password2' details={submitErrors.details} className={classes.fieldError}/>}
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox
                      value="corporate"
                      onChange={handleCompanyClick}
                      color="primary"/>}
                    label={t("Corporate account")}
                  />
                </Grid>
                {isCompany && <Grid item xs={12}>
                  <Field
                    type='text'
                    label={t('Company Name')}
                    name='company_name'
                    component={TextField}
                    fullWidth
                  />
                  {submitErrors &&
                  <FieldErrorRenderer field='company_name' details={submitErrors.details}
                                      className={classes.fieldError}/>}
                </Grid>
                }
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    {t('Register')}
                  </Button>
                </Grid>
              </Grid>
            </Form>

            <Typography variant="subtitle2" className={classes.form}>
              {t('Already have an account')}?
              <Link component={RouterLink} to="/login">
                {t('Login')}
              </Link>
            </Typography>
            {submitSuccess &&
            <SnackbarContent className={classes.success} message={
              <span id="client-snackbar" className={classes.message}>Account registered please see your email</span>
            }
            />}
            {submitErrors && submitErrors.detail &&
            <SnackbarContent className={classes.error} message={
              <span>{submitErrors.detail && submitErrors.detail}</span>
            }
            />}
          </Paper>
        </main>)}
    </Formik>

  )
};

export default RegisterPage;
