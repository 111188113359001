import React, { useEffect } from 'react';
import { makeStyles, Grid, Typography, Paper} from '@material-ui/core';
import RecipientList from './RecipientsList';
import AddRecipient from '../AddRecipient';
import {useTranslation} from 'react-i18next'
import { useDispatch } from 'react-redux';
import { clearEmailRecipients } from '../../../actions';

const useStyles= makeStyles(theme=>({
    
    recipientlist:{
      paddingRight: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    recipientAdd:{
      paddingTop: theme.spacing(2),
      
    }

}));

const MailRecipients = ()=>{
  const classes = useStyles();
  const {t} = useTranslation();
  const dispatch = useDispatch();

  useEffect(()=>{
    return ()=>{
      dispatch(clearEmailRecipients())
    }
  },[dispatch])

  return<>
  <Paper className={classes.paper}>
    <Grid container>
      <Grid item md={12} xs={12} className={classes.recipientlist}>
        <Typography variant="h5"gutterBottom>{t("Mail recipient list")}</Typography>
        <RecipientList/>
      </Grid>
      <Grid item md={12} xs={12} className={classes.recipientAdd}>
      <Typography variant="h5"gutterBottom>{t("Add a recipient")}</Typography>
        <AddRecipient/>
      </Grid>
    </Grid>
  </Paper>
  </>
}

export default MailRecipients;