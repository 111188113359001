export const isUserLoading= state => state.user.isLoading;

export const userErrors = state => state.user.errors;

export const jwtTokenSelector = state => state.user.token;

export const userEmailSelector = state => state.user.email;

export const emailRecipientsSelector = state => state.user.emailRecipients;

export const emailRecipientsLoadingSelector = state => state.user.emailRecipientsLoading;

export const recipientAddIsLoadingSelector = state => state.user.recipientAddIsLoading;