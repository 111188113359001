import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router'
import * as actions from '../actions/userActions';
import userService from '../services/userService';


export function* loginUser(action) {
  try {
    const data = yield call(userService.login, action.email, action.password);
    yield put(actions.resolveLogin(data.user.email, data.user.username, data.token));
    yield put(push('/'));
  } catch (e) {

    yield put(actions.rejectLogin(e));
  }
}

export function* resetPassword(action) {
  yield call(userService.resetPassword, action.email);
  yield put(push('/'));
}

export function* requestEmailRecipients(action){
  try{
    const data =yield call(userService.getEmailRecipients, action.jwtToken);
    const hasMore = data.next != null;
    yield put(actions.resolveEmailRecipients(data.results,hasMore));
    if(hasMore){
      yield put(actions.requestEmailRecipientsNextPage(data.next,action.jwtToken));
    }

  }catch(e){
    yield put (actions.rejectEmailRecipients(e));
  }
}

export function* requestEmailRecipientsNextPage(action){
  try{
    const data = yield call(userService.getEmailRecipientsNextPage, action.url, action.jwtToken);
    const hasMore = data.next != null;
    yield put(actions.resolveEmailRecipientsNextPage(data.results,hasMore));

    if(hasMore){
      yield put(actions.requestEmailRecipientsNextPage(data.next,action.jwtToken))
    }
  }catch(e){
    yield put (actions.rejectEmailRecipientsNextPage(e))
  }
}

export function * requestSetPrimaryEmail(action){
  try{
    const data = yield call (userService.toggleEmailPrimaryRecipient,action.id,action.primary,action.jwtToken);
    yield put(actions.resolveTogglePrimaryRecipient(data));
  }catch(e){
    yield put(actions.rejectTogglePrimaryRecipient(e));
  }
}

export function * requestRemoveEmailRecipient(action){
  try{
    yield call (userService.deleteEmailRecipient,action.id,action.jwtToken);
    yield put(actions.resolveRecipientDelete(action.id));
  }catch(e){
    yield put(actions.rejectRecipientDelete(e))
  }
}

export default function* loginUserSaga() {
  // takeLatest retrieves the latest action that matches the first argument
  // (cancels all other pending) and creates a saga given by the second argument
  // we can choose to pass more args which will be passed to the saga in addition
  // to the incoming action
  yield takeLatest(actions.USER_ACTIONS.LOGIN_REQUESTED, loginUser);
  yield takeLatest(actions.USER_ACTIONS.PASSWORD_RESET_REQUESTED, resetPassword);
  yield takeLatest(actions.USER_ACTIONS.RECIPIENTS_REQUESTED, requestEmailRecipients);
  yield takeLatest(actions.USER_ACTIONS.RECIPIENT_TOGGLE_PRIMARY_REQUEST, requestSetPrimaryEmail);
  yield takeLatest(actions.USER_ACTIONS.RECIPIENT_REMOVE_REQUEST, requestRemoveEmailRecipient);
}
