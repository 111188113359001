import React from 'react';

import ConnectivityFilters from './connectivityFilters.jsx';
import BatteryFilters from './batteryFilters.jsx';
import AlarmFilters from './alarmFilters.jsx';
import TypeFilters from './typeFilters.jsx';
import { useTranslation } from 'react-i18next';

const SensorFilters = () => {
  const { t } = useTranslation();
  // render() {
  return (
    <div>
      <h4>{t('By Type')}</h4>
      <TypeFilters />
      <h4>{t('By Connectivity')}</h4>
      <ConnectivityFilters />
      <h4>{t('By Battery Percentage')}</h4>
      <BatteryFilters />
      <h4>{t('By Alarm Status')}</h4>
      <AlarmFilters />
    </div>
  );
  // }
}

export default SensorFilters;