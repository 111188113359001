import React from 'react'
import { Radio, Tooltip } from '@material-ui/core'
import { requestTogglePrimaryRecipient } from '../../../actions';
import { jwtTokenSelector } from '../../../selectors/userSelectors';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const TogglePrimary = ({primary, id, verified})=>{
  
  const jwtToken = useSelector(jwtTokenSelector);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  
  const handleToggle = ()=>{
    dispatch(requestTogglePrimaryRecipient(id,!primary,jwtToken));
  }

  return<Tooltip title={t("Set as primary")} placement="top">
    <Radio
        edge="end"
        checked = {primary}
        disabled = {!verified}
        onChange={handleToggle}
      />
    </Tooltip>
}

export default TogglePrimary;