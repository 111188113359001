import {
  SENSOR_LIST_ACTIONS,
  SET_CONNECTIVITY_FILTER, CONNECTIVITY_FILTERS,
  SET_BATTERY_FILTER, BATTERY_FILTERS, SET_ALARM_FILTER,
  ALARM_FILTERS, SET_TYPE_FILTER, TYPE_FILTER_ALL,
  SET_SEARCH_FILTER, SENSOR_SELECTED, CLEAR_SELECTED_SENSOR,
  SENSOR_CONFIG_UPDATED, SENSOR_NAME_UPDATED, MEASUREMENT_CONFIG_UPDATED,
  SENSOR_ACTIONS,
  SENSOR_NOTIFICATIONS_UPDATED

} from '../actions';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
// import cloneDeep from 'lodash/cloneDeep';
import produce from 'immer';

const initialSensorState = {
  sensors: [],
  hasMore: false,
  sensor_types: [TYPE_FILTER_ALL],
  selectedSensor: null,
  selectedSensorLoading: false,
  selectedSensorExists: false,
  error: null,
  isLoading: true,
  muteIsLoading: false,
  connectivityFilter: CONNECTIVITY_FILTERS.ALL,
  batteryFilter: BATTERY_FILTERS.ALL,
  alarmFilter: ALARM_FILTERS.ALL,
  typeFilter: TYPE_FILTER_ALL,
  searchPattern: '',
  lastListUpdate: null,
  lastSensorrUpdate: null,
};

const sensorReducer = (state = initialSensorState, action) => {
  switch (action.type) {
    case SENSOR_ACTIONS.SENSOR_RESOLVE:{
      return{
        ...state,
        selectedSensor: action.sensor,
        selectedSensorLoading: false,
        selectedSensorExists: true,
        lastSensorUpdate: new Date(),
        sensors: state.sensors.length > 0 && state.sensors !== null ? state.sensors.map((item)=>{
          if(item.ref_id !== action.sensor.ref_id) return item;

          let newItem =cloneDeep(item);
          newItem = action.sensor;
          return newItem;
        }): [],
      }
    }
    case SENSOR_ACTIONS.SENSOR_REJECTED:{
      return{
        ...state,
        selectedSensorLoading: false,
        selectedSensorExists: false,
        error: action.error,
      }
    }
    case SENSOR_ACTIONS.SENSOR_REQUEST:{
      return {
        ...state,
        selectedSensorLoading: true,
      };
    }
    case SENSOR_LIST_ACTIONS.SENSOR_LIST_REQUESTED:
      return {
        ...state,
        error: null,
        isLoading: true,
        muteIsLoading: action.mute,
      };
    case SENSOR_LIST_ACTIONS.SENSOR_LIST_RESOLVED:
      return {
        ...state,
        sensors: action.sensors,
        sensor_types: [TYPE_FILTER_ALL, ...action.sensor_types],
        error: null,
        isLoading: action.hasMore,
        muteIsLoading: action.mute,
        lastListUpdate: new Date(),
      };
    case SENSOR_LIST_ACTIONS.SENSOR_LIST_REJECTED:
      return {
        ...state,
        sensors: [],
        sensor_types: [],
        error: action.error,
        isLoading: false,
      };
    case SENSOR_LIST_ACTIONS.SENSOR_LIST_NEXT_PAGE_RESOLVED:
      return {
        ...state,
        sensors: [...action.sensors, ...state.sensors],
        error: null,
        isLoading: action.hasMore,
        muteIsLoading:  action.mute,
      };
    case SENSOR_LIST_ACTIONS.SENSOR_LIST_NEXT_PAGE_REJECTED:
      return {
        ...state,
        sensors: [],
        error: action.error,
        isLoading: false,
      };
    case SENSOR_LIST_ACTIONS.SENSOR_LIST_CLEAR:
      return {
        ...initialSensorState,
      }
    case SET_CONNECTIVITY_FILTER:
      return {
        ...state,
        connectivityFilter: action.name,
      };
    case SET_BATTERY_FILTER:
      return {
        ...state,
        batteryFilter: action.name,
      };
    case SET_ALARM_FILTER:
      return {
        ...state,
        alarmFilter: action.name,
      };
    case SET_TYPE_FILTER:
      return {
        ...state,
        typeFilter: action.name,
      };
    case SET_SEARCH_FILTER:
      return {
        ...state,
        searchPattern: action.pattern,
      };
    case SENSOR_SELECTED:
      return {
        ...state,
        selectedSensor: find(state.sensors, x => x.ref_id === action.sensorRef),
        selectedSensorExists: find(state.sensors, x => x.ref_id === action.sensorRef)? true:false,
      }
    case CLEAR_SELECTED_SENSOR:
      return {
        ...state,
        selectedSensor: null,
        selectedSensorExists:false,
      }
    case SENSOR_CONFIG_UPDATED:
      return {
        ...state,
        sensors: state.sensors.map((item) => {
          if (item.ref_id !== action.sensorRef) return item;

          return {
            ...item,
            configuration: { ...action.newConfiguration }
          }
        }),
        selectedSensor: {
          ...state.selectedSensor,
          configuration: { ...action.newConfiguration }
        }
      }
    case SENSOR_NOTIFICATIONS_UPDATED:
    {      
      return{
        ...state,
        sensors: state.sensors.map((item) => {
          if (item.ref_id !== action.sensorRef) return item;

          return {
            ...item,
            notify_when_offline:action.newNotificationSettings.offline,
            notify_when_online: action.newNotificationSettings.online
          }
        }),
        selectedSensor: {
          ...state.selectedSensor,
          notify_when_offline: action.newNotificationSettings.offline ,
          notify_when_online: action.newNotificationSettings.online 
        }
      }
    }
    case SENSOR_NAME_UPDATED:
      return {
        ...state,
        sensors: state.sensors.map((item) => {
          if (item.ref_id !== action.sensorRef) return item;

          let newItem = cloneDeep(item);
          newItem.name = action.newName;
          return newItem;
        }),
        selectedSensor: {
          ...state.selectedSensor,
          name: action.newName
        }
      }
    case MEASUREMENT_CONFIG_UPDATED:
      return produce(state, draft => {
        const sensorIdx = findIndex(draft.sensors, s => s.ref_id === action.sensorRef)
        const measurementIdx = findIndex(draft.sensors[sensorIdx].measurement_configs, s => s.id === action.configRef)
        draft.sensors[sensorIdx].measurement_configs[measurementIdx] = action.newConfig;
        
        const measurementIdx2 = findIndex(draft.selectedSensor.measurement_configs, s => s.id === action.configRef)
        draft.selectedSensor.measurement_configs[measurementIdx2] = action.newConfig;
      });
    default:
      return state;
  }
};

export default sensorReducer;
