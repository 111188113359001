import {call,put, takeLatest,select} from 'redux-saga/effects';
import * as actions from '../actions';
import {sensorService} from '../services';
import {jwtTokenSelector} from '../selectors/userSelectors';
import {clearValuesOfDI} from '../common/constants'

export function* requestSingleSensor(action){
  try{
    const jwtToken = yield select(jwtTokenSelector);
    
    const sensor = yield call(sensorService.getSingleSensor,action.sensorRef,jwtToken);

    yield put(actions.resolveSensor(clearValuesOfDI(sensor)));

  }catch(e){
    yield put(actions.rejectSensor(e));
  };
}

export default function* sensorSaga(){
  yield takeLatest(actions.SENSOR_ACTIONS.SENSOR_REQUEST, requestSingleSensor);
  yield takeLatest(actions.SENSOR_ACTIONS.SENSOR_REFRESH, requestSingleSensor);
}