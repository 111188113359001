import { EVENTS_ACTIONS } from '../actions';
import moment from 'moment';

const initialEventsState = {
  events: [],
  startDate: new Date(),
  endDate: moment().subtract(15, 'days'),
  isLoading: false,
  error: null
}
const eventsReducer = (state = initialEventsState, action) => {
  switch (action.type) {
    case EVENTS_ACTIONS.EVENTS_REQUESTED:
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
        error: null,
        isLoading: true,
      }
    case EVENTS_ACTIONS.EVENTS_RESOLVED:
      return {
        ...state,
        error: null,
        events: action.events,
        isLoading: false,
      }
    case EVENTS_ACTIONS.EVENTS_REJECTED:
      return {
        ...state,
        error:action.error,
        events:[],
        isLoading:false,
      }
    case EVENTS_ACTIONS.CLEAR_EVENTS:
      return {
        ...initialEventsState
      }
    default:
      return state;
  }
}

export default eventsReducer;