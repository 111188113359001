import { call, put, takeLatest, select } from 'redux-saga/effects';
import uniq from 'lodash/uniq';
import map from 'lodash/map';

import * as actions from '../actions';
import { sensorService } from '../services';
import {jwtTokenSelector} from '../selectors/userSelectors';
import Constants,{clearValuesOfDI} from '../common/constants';
import {filter} from 'lodash';

function filterDISensors (sensors){
  const discomfort_sensors = filter(sensors,sensor=>
    Constants.getSensorType(sensor.sensor_type) === Constants.DISCOMFORT_INDEX_SENSOR_TYPE);

  if(discomfort_sensors.length === 0){
    return sensors;
  }

  discomfort_sensors.forEach((sensor)=>{
    clearValuesOfDI(sensor);
  });

  return sensors;
}

export function* sensorList(action) {
  try {
    const jwtToken = yield select(jwtTokenSelector);
    const data = yield call(sensorService.getSensorList, jwtToken);
    const types = uniq(map(data.results, r => r.sensor_type));
    const hasMore = data.next != null;

    yield put(actions.resolveSensorList(filterDISensors(data.results), types, hasMore, action.mute));

    if (hasMore){
      yield put(actions.requestNextPage(data.next,action.mute))
    };
  } catch (e) {
    yield put(actions.rejectSensorList(e));
    if (e.result && e.result === "unauthorized")
      yield put(actions.tokenExpired());
  };
}

export function* sensorNextPage(action) {
  try {
    const jwtToken = yield select(jwtTokenSelector);
    const data = yield call(sensorService.getNextPage,action.page_url, jwtToken);
    const hasMore = data.next != null;
    yield put(actions.resolveNextPage(filterDISensors(data.results), hasMore,action.mute));

    if (hasMore){
      yield put(actions.requestNextPage(data.next,action.mute))
    };
  } catch (e) {
    yield put(actions.rejectNextPage(e));
    if (e.result && e.result === "unauthorized")
      yield put(actions.tokenExpired());
  }
}

export default function* sensorListSaga() {
  // takeLatest retrieves the latest action that matches the first argument
  // (cancels all other pending) and creates a saga given by the second argument
  // we can choose to pass more args which will be passed to the saga in addition
  // to the incoming action
  yield takeLatest(actions.SENSOR_LIST_ACTIONS.SENSOR_LIST_REQUESTED, sensorList);
  yield takeLatest(actions.SENSOR_LIST_ACTIONS.SENSOR_LIST_NEXT_PAGE_REQUESTED, sensorNextPage);
}
