import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { setSensorSelected } from '../actions';
import { selectedSensor } from '../selectors/sensorSelectors';
import { Grid, Paper, Divider } from '@material-ui/core'
import EventsConfigurator from '../components/SensorEvents/EventsConfigurator'
import EventsPanel from '../components/SensorEvents/EventsPanel'
import {useTranslation} from 'react-i18next';
import ExportMonthlyData from '../components/SensorMeasurements/ExportMonthlyData';
import {NotFound} from '../common/NotFound';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  paperHeader: {
    fontSize: 18,
    padding: theme.spacing(2),
  },
}));

const SensorEventsPage = ({ match }) => {

  const sensorRefId = match && match.params && match.params.id;
  const dispatch = useDispatch();
  const {t} = useTranslation();
  dispatch(setSensorSelected(sensorRefId));
  const sensorDetail = useSelector(selectedSensor);

  const classes = useStyles();

  if (sensorDetail == null) {
    return <NotFound text={"Sensor not found"}/>
  }

  return (
    <>
      <Typography variant="h4" gutterBottom component="h2">{t("Events of")} {sensorDetail.name || sensorDetail.id}</Typography>
      <Grid className={classes.root} container spacing={3}>
        <Grid item xs={12} md={4} lg={4}>
          <Grid className={classes.root} container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Paper className={classes.paper}>
                <EventsConfigurator />
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Paper className={classes.paper}>
                <ExportMonthlyData measurements={false} events={true}/>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <Paper className={classes.paper}>
            <Typography className={classes.paperHeader} variant="h4">{t("Events")}</Typography>
            <Divider />
            <EventsPanel />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default SensorEventsPage;