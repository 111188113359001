import React from 'react';
import {makeStyles, IconButton,Tooltip} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import isEmpty from 'lodash/isEmpty';
import amber from '@material-ui/core/colors/amber';
import {useTranslation} from 'react-i18next';
import LinearProgress from '@material-ui/core/LinearProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }, grow: {
    flexGrow: 1,
  },
  default: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main)
  },
  changed: {
    backgroundColor: amber[500],
    color: theme.palette.getContrastText(amber[500])
  },
  errors: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.getContrastText(theme.palette.error.main),
  },
  tooltipText:{
    fontSize: '12px',
  }
}
));

const ChangePasswordFormHeader = ({
  title,
  errors,
  handleReset,
  isSubmitting,
  dirty
})=>{

  const classes = useStyles();
  const {t} = useTranslation();
  let headerClass = classes.default;
  if(!isEmpty(errors)) headerClass = classes.errors;
  else if(dirty) headerClass = classes.changed;
  return <>
  <AppBar className={headerClass} position='relative'>
    <Toolbar>
      <Typography variant='h6'>{title}</Typography>
      <div className={classes.grow} />
      
      <IconButton disabled={isSubmitting || !dirty} onClick={handleReset}
                  className={headerClass}>
        <FontAwesomeIcon size="xs" icon={["far", "undo"]}/>
      </IconButton>
      
      <Tooltip title={
          <>
            <li className={classes.tooltipText}>{t("The password must contain at least 8 characters")}</li>
            <li className={classes.tooltipText}>{t("Old and new password need to be different")}</li>
            <li className={classes.tooltipText}>{t("The password must be a combination of alphanumeric characters and/or symbols")}</li>
          </>
        } placement="top" arrow>
        <span>
          <FontAwesomeIcon  size="lg" icon={["far", "question"]}/>
        </span>
      </Tooltip>      
    </Toolbar>
  </AppBar>
  {isSubmitting && <LinearProgress/>}
  </>;
};

export default ChangePasswordFormHeader;