import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as actions from '../actions';
import { eventsService } from '../services';
import { jwtTokenSelector } from '../selectors/userSelectors';
import { selectedSensor } from '../selectors/sensorSelectors';

export function* events(action) {
  try {
    const jwtToken = yield select(jwtTokenSelector);
    const sensorRef = yield select(selectedSensor);
    const data = yield call(eventsService.getEvents,sensorRef.ref_id, action.startDate, action.endDate, jwtToken);
    yield put(actions.resolveEvents(data.results));
  } catch (e) {
    yield put(actions.rejectEvents(e));
    if (e.result && e.result === "unauthorized")
      yield put(actions.tokenExpired());
  }
}

export default function* eventsSaga() {
  yield takeLatest(actions.EVENTS_ACTIONS.EVENTS_REQUESTED, events);
}