import { EVENT_CHART_ACTIONS } from '../actions'

const initialEventChartState = {
  showUserEvents: true,
  showAlarmEvents: true,
  showConnectivityEvents: true,
  showChipStateEvents: true,
};

const eventChartReducer = (state = initialEventChartState, action) => {
  switch (action.type) {
    case (EVENT_CHART_ACTIONS.TOGGLE_ALARM_EVENTS):
      return {
        ...state,
        showAlarmEvents: !state.showAlarmEvents,
      }

    case (EVENT_CHART_ACTIONS.TOGGLE_USER_EVENTS):
      return {
        ...state,
        showUserEvents: !state.showUserEvents,

      }
    case (EVENT_CHART_ACTIONS.TOGGLE_CONNECTIVITY_EVENTS):
      return {
        ...state,
        showConnectivityEvents: !state.showConnectivityEvents,
      }
    case (EVENT_CHART_ACTIONS.TOGGLE_CHIP_STATE_EVENTS):
      return {
        ...state,
        showChipStateEvents: !state.showChipStateEvents,
      }
    default:
      return state;
  }
};

export default eventChartReducer;