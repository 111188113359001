import React from 'react';
import { connect } from 'react-redux';

import * as actions from '../../actions';
import FilterLink from './filterLink.jsx';

const mapDispatchToProps = dispatch => {
  return {
    onFilterClick: name => {
      dispatch(actions.setConnectivityFilter(name));
    },
  };
};

const mapStateToProps = state => ({
  currentFilter: state.sensors.connectivityFilter,
});

const ConnectivityFilterLink = connect(mapStateToProps, mapDispatchToProps)(FilterLink);

const ConnectivityFilters = () => {  
  
  return <div id="connectivity-filters" className="list-group">
    <ConnectivityFilterLink filterName={actions.CONNECTIVITY_FILTERS.ALL} />
    <ConnectivityFilterLink filterName={actions.CONNECTIVITY_FILTERS.ONLINE} />
    <ConnectivityFilterLink filterName={actions.CONNECTIVITY_FILTERS.OFFLINE} />    
  </div>;
};

export default ConnectivityFilters;