import React, { useState } from 'react'
import { makeStyles, Paper, Grid, MenuItem, SnackbarContent, FormControl, InputLabel} from '@material-ui/core';
import { Select} from "formik-material-ui";
import green from "@material-ui/core/colors/green";
import * as yup from "yup";
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { jwtTokenSelector } from '../../selectors/userSelectors';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import EditFormHeader from './EditFormHeader';
import Constants, {LowAlarmDIMapping,HighAlarmDIMapping} from '../../common/constants';
import { sensorService } from '../../services';
import { updateMeasurementConfig } from '../../actions';
import FieldErrorRenderer from './FieldErrorRenderer';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  fieldError: {
    color: theme.palette.error.main
  },
  paper: {
    marginBottom: theme.spacing(2),
  },
  mg2: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  formControl: {
    display: 'flex'
  }
}));

const schema = yup.object().shape({
  alarm_low: yup.number().lessThan(yup.ref('alarm_high')),
  alarm_high: yup.number().moreThan(yup.ref('alarm_low')),
});

const DiscomfortMeasurementConfig = ({measurementConfig, sensorRef}) =>{
  const classes = useStyles();
  const jwtToken = useSelector(jwtTokenSelector);
  const dispatch = useDispatch();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitErrors, setSubmitErrors] = useState(null);
  const measurementConfigRef = measurementConfig.id;
  const {t} = useTranslation();

  const submitConfig = (values,actions)=>{
    sensorService.updateMeasurementConfig(values, measurementConfigRef, sensorRef, jwtToken)
      .then(() => {
        setSubmitSuccess(true);
        setSubmitErrors(null);
        actions.resetForm(values)
        dispatch(updateMeasurementConfig(sensorRef, measurementConfigRef, values));
      })
      .catch((err) => {
        setSubmitSuccess(false);
        setSubmitErrors(err);
        actions.setSubmitting(false);
      }
      )
  }

  return  <Formik
    initialValues = {measurementConfig}
    onSubmit = {submitConfig}
    validationSchema = {schema}
    >
    {({
      errors,
      touched,
      dirty,
      handleSubmit,
      isSubmitting,
      handleReset,
    }) => (
      <Paper className={classes.paper}>
        <EditFormHeader errors={errors} handleReset={handleReset} handleSubmit={handleSubmit} isSubmitting={isSubmitting}
            title={t('Measurement configuration')+`: ${t(measurementConfig.measurement_type)}`} touched={touched} dirty={dirty} />
        <Form>
          <Grid container spacing={3} className={classes.root}>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="low_alrm">{t("Alarm low")}</InputLabel>
                <Field
                  name = "alarm_low"
                  component={Select}
                  >
                    {_.map(Constants.DI_DESCRIPTIONS,(d,i)=>{
                      return <MenuItem value={LowAlarmDIMapping[d]} key={i}>{t(d)}</MenuItem>
                    })}
                  </Field>
                  {submitErrors &&
                  <FieldErrorRenderer field='alarm_low' details={submitErrors.details} className={classes.fieldError} />}
                </FormControl>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="high_alrm">{t("Alarm high")}</InputLabel>
                <Field
                  name="alarm_high"
                  component={Select}
                  >
                    {_.map(Constants.DI_DESCRIPTIONS,(d,i)=>{
                      return <MenuItem value={HighAlarmDIMapping[d]} key={i}>{t(d)}</MenuItem>
                    })}
                </Field>
                {submitErrors &&
                  <FieldErrorRenderer field='alarm_high' details={submitErrors.details} className={classes.fieldError} />}
              </FormControl>
            </Grid>
          </Grid>
        </Form>
        {submitSuccess &&
        <SnackbarContent className={classes.success} message={
          <span id="client-snackbar" className={classes.message}>{t('Configuration saved successfully')}</span>
        } />}
        {submitErrors && submitErrors.detail &&
        <SnackbarContent className={classes.error} message={
          <span>{submitErrors.detail && submitErrors.detail}</span>
        } />}
      </Paper>
      )}
    </Formik>
};

export default DiscomfortMeasurementConfig;