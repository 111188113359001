import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import USBBatteryIcon from '../sensorList/USBBatteryIcon';
import SignalStrengthIcon from '../sensorList/signalStrengthIcon';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import OnlineStatus from './OnlineStatus';
import max from 'lodash/max';
import map from 'lodash/map';
import moment from 'moment';
import 'moment-duration-format';
import AlarmChips from './AlarmChips';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { refreshSensor} from '../../actions';
import { isRefreshEnabled, refreshRate } from '../../selectors/settingsSelectors';
import { selectedSensor } from '../../selectors/sensorSelectors';
import { useInterval } from '../../common/intervalTimer';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: 'left',
  },
  sensor_name: {
    fontSize: 18,
    marginTop: theme.spacing(1),
  },
  mg2: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  mg2Full: {
    margin: theme.spacing(2)
  },
  description: {
    color: theme.palette.text.secondary,
  },
  chip: {
    margin: theme.spacing(1),
  },
  details: {
    marginTop: theme.spacing(0.5),
  }
}))

const DetailRow = ({ description, value }) => {
  const classes = useStyles();

  return <TableRow>
    <TableCell className={classes.description}>{description}</TableCell>
    <TableCell>{value}</TableCell>
  </TableRow>
}

const ConfigStatusRow = ({ description, configValue, statusValue }) => {
  const classes = useStyles();
  const {t }=useTranslation();

  return <TableRow>
    <TableCell className={classes.description}>{description}</TableCell>
    <TableCell>

      {configValue !== statusValue && <Chip label={`${t('wanted')}: ${configValue}`} className={classes.chip} color="primary" />}
      <Chip label={`${t('reported')}: ${statusValue}`} className={classes.chip} />
    </TableCell>
  </TableRow>
}

const BatteryDetailRow = ({ usb, battery, t }) => {
  const classes = useStyles();

  return <TableRow>
    <TableCell className={classes.description}>{t('Power')}</TableCell>
    <TableCell>
      <USBBatteryIcon isUSB={usb} battery={battery} />
    </TableCell>
  </TableRow>
}

const RSSIDetailRow = ({ rssi, t }) => {
  const classes = useStyles();
  return <TableRow>
    <TableCell className={classes.description}>{t("RSSI")}</TableCell>
    <TableCell>
      <SignalStrengthIcon rssi={rssi} />
    </TableCell>
  </TableRow>
}

const SensorBaseDetails = ({ ref_id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const sensorDetail = useSelector(selectedSensor);

  const delay_in_ms = useSelector(refreshRate);
  const delay = useSelector(isRefreshEnabled) ? delay_in_ms : null;

  const { t} = useTranslation();
  
  function refreshCurrent(){
    dispatch(refreshSensor(ref_id));
  }

  useInterval(()=>{
    refreshCurrent();
  },delay);
  
  const last_measurement = max(map(sensorDetail.latest_values, val => val.measurement_time));
  const last_measurement_str = moment(last_measurement).format('LLLL');
  const last_update = moment(sensorDetail.status.last_update).format('LLLL');

  return <Paper className={classes.paper}>
    <Grid container className={classes.mg2}>
      <Grid item xs={12} sm={10}>
        <Typography variant="body1" className={classes.details} >{t('Sensor details')}</Typography>
      </Grid>
      <Grid item xs={12} sm={2}>
        <OnlineStatus isOnline={sensorDetail.is_online} />
      </Grid>
    </Grid>
    <Divider />
    <Box m={2}>
      <Typography variant="body1" display="inline">{t('Alarms')}: </Typography>
      <AlarmChips
        className={classes.mg2Full}
        alarm={sensorDetail.status.has_alarm}
        preAlarm={sensorDetail.status.has_prealarm}
        sensorFault={sensorDetail.status.sensor_chip_state !== 0} />
    </Box>
    <Divider />
    <Table>
      <TableBody>
        <DetailRow description={t("Sensor Type")} value={sensorDetail.sensor_type} />
        <DetailRow description={t("Id")} value={sensorDetail.id} />
        <DetailRow description={t("Mac Address")} value={sensorDetail.mac_address} />
        <ConfigStatusRow

          description={t("Measure every")}
          configValue={moment.duration(sensorDetail.configuration.sleep_seconds*1000).format("HH:mm:ss")}
          statusValue={moment.duration(sensorDetail.status.sleep_seconds*1000).format("HH:mm:ss")} />

        <ConfigStatusRow
          description={t("Send every (measurements)")}
          configValue={sensorDetail.configuration.wakeup_times}
          statusValue={sensorDetail.status.wakeup_times} />
        <ConfigStatusRow

          description={t("Operational mode")}
          configValue={sensorDetail.configuration.operational_mode === 1 ? t("Always On") : t("Normal")}
          statusValue={sensorDetail.status.operational_mode === 1 ? t("Always On") : t("Normal")} />

        <ConfigStatusRow
          description={t("Alarm Enabled")}
          configValue={sensorDetail.configuration.alarm_enable}
          statusValue={sensorDetail.status.alarm_enable} />
        <ConfigStatusRow
          description={t("Alarm Delay")}
          configValue={sensorDetail.configuration.alarm_delay}
          statusValue={sensorDetail.status.alarm_delay} />
        <DetailRow description={t("Firmware Version")} value={sensorDetail.status.firmware_version} />
        <RSSIDetailRow rssi={sensorDetail.status.latest_rssi} t={t} />
        <BatteryDetailRow battery={sensorDetail.status.latest_battery} usb={sensorDetail.status.latest_usb} t={t} />
        <DetailRow description={t("Last status update")} value={last_update} />
        <DetailRow description={t("Last measurement")} value={last_measurement_str} />
      </TableBody>
    </Table>
  </Paper>
}

export default SensorBaseDetails;
