import React from 'react';
import { makeStyles} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import deepPurple from '@material-ui/core/colors/deepPurple';
import orange from '@material-ui/core/colors/orange';
import deepOrange from '@material-ui/core/colors/deepOrange';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  alarm: {
    backgroundColor: deepOrange[500]
  },
  preAlarm: {
    backgroundColor: orange[500]
  },
  sensorFault: {
    backgroundColor: deepPurple[500]
  },
}));

const AlarmChips = ({alarm, preAlarm, sensorFault}) => {
  const classes = useStyles();
  const {t} = useTranslation();

  return <>
    {!alarm && !preAlarm && !sensorFault && <Typography display="inline">{t('No alarms')}</Typography>}
    {alarm && <Chip classes={{
      root: classes.root,
      colorPrimary: classes.alarm
    }} label={t("Alarm")} color="primary"/>}
    {preAlarm && <Chip classes={{
      root: classes.root,
      colorPrimary: classes.preAlarm
    }} label={t("Pre Alarm")} color="primary"/>}
    {sensorFault && <Chip classes={{
      root: classes.root,
      colorPrimary: classes.sensorFault
    }} label={t("Sensor Fault")} color="primary"/>}
    </>;
}

export default AlarmChips;
