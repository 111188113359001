import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
  button: {
    width: '100%',
  },
  input: {
    display: 'none',
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    margin: 0,
    padding: theme.spacing(0.5),
  },
}));

const FilterLink = ({ currentFilter, filterName, onFilterClick }) => {
  const classes = useStyles();
  const {t} = useTranslation();

  // const color = { color: currentFilter === filterName ? 'primary' : null };
  const color = { color: currentFilter === filterName ? 'primary' : 'default' };
  //to prevent Uncaught Error: Material-UI: capitalize(string) expects a string argument
  
  return <ListItem className={classes.root}>
    <Button
      {...color}
      variant="contained"
      onClick={() => onFilterClick(filterName)} className={classes.button}>
      {t(filterName)}
    </Button>
  </ListItem>
};

FilterLink.propTypes = {
  filterName: PropTypes.string.isRequired,
  currentFilter: PropTypes.string.isRequired,
  onFilterClick: PropTypes.func.isRequired,
};

export default FilterLink;