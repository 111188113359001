import { DASHBOARD_ACTIONS } from '../actions';
import moment from "moment";

const initialDashboardState = {
  sensors: null,
  online: null,
  alarm: null,
  battery_low: null,
  isLoading: false,
  error: null,
  lastResolved: null,
  sideMenuShow: true,
};

const dashboardReducer = (state = initialDashboardState, action) => {
  switch (action.type) {
    case DASHBOARD_ACTIONS.DASHBOARD_REQUESTED:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case DASHBOARD_ACTIONS.DASHBOARD_RESOLVED:
      return {
        ...state,
        error: null,
        isLoading: false,
        sensors: action.sensors,
        online: action.online,
        alarm: action.alarm,
        battery_low: action.battery_low,
        lastResolved: moment(),
      };
    case DASHBOARD_ACTIONS.DASHBOARD_REJECTED:
      return {
        ...initialDashboardState,
        error: action.error,
      };
    case DASHBOARD_ACTIONS.DASHBOARD_CLEAR:
      return {
        ...initialDashboardState,
        sideMenuShow: state.sideMenuShow,
      }
    case DASHBOARD_ACTIONS.SIDE_MENU_TOGGLE:
      return {
        ...state,
        sideMenuShow: !state.sideMenuShow,
      }
    default:
      return state;
  }
}

export default dashboardReducer;
