import React, { useState } from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import green from "@material-ui/core/colors/green";
import { useDispatch, useSelector } from "react-redux";
import { jwtTokenSelector } from "../../selectors/userSelectors";
import Paper from "@material-ui/core/Paper";
import EditFormHeader from "./EditFormHeader";
import { Field, Form, Formik } from "formik";
import Grid from "@material-ui/core/Grid";
import { Select } from "formik-material-ui";
import FieldErrorRenderer from "./FieldErrorRenderer";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@material-ui/core';
import { sensorService } from '../../services';
import { updateMeasurementConfig } from '../../actions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  fieldError: {
    color: theme.palette.error.main
  },
  paper: {
    marginBottom: theme.spacing(2),
  },
  mg2: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  formControl: {
    display: 'flex'
  }
}));

const BooleanConfig = ({ measurementConfig, sensorRef }) => {
  const classes = useStyles();
  const jwtToken = useSelector(jwtTokenSelector);
  const dispatch = useDispatch();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitErrors, setSubmitErrors] = useState(null);
  const measurementConfigRef = measurementConfig.id;
  const { t} = useTranslation();

  const submitConfig = (values, actions) => {
    sensorService.updateMeasurementConfig(values, measurementConfigRef, sensorRef, jwtToken)
      .then(() => {
        setSubmitSuccess(true);
        setSubmitErrors(null);
        actions.resetForm(values)
        dispatch(updateMeasurementConfig(sensorRef, measurementConfigRef, values));
      })
      .catch((err) => {
        setSubmitSuccess(false);
        setSubmitErrors(err);
        actions.setSubmitting(false);
      }
      )
  };

  return <Formik
    initialValues={measurementConfig}
    onSubmit={submitConfig}
  >
    {({
      errors,
      touched,
      dirty,
      handleSubmit,
      isSubmitting,
      handleReset,
    }) => (
        <Paper className={classes.paper}>
          <EditFormHeader
            errors={errors} handleReset={handleReset} handleSubmit={handleSubmit} isSubmitting={isSubmitting}
            title={t('Trigger condition')+`: ${t(measurementConfig.measurement_type)}`} touched={touched} dirty={dirty} />
          <Form>
            <Grid container spacing={3} className={classes.root}>
              <Grid item xs={12}>
                <Field
                  type='Select'
                  name='trigger_condition'
                  component={Select}
                  fullWidth
                >
                <MenuItem value="1">{t('Trigger when true')}</MenuItem>
                <MenuItem value="2">{t('Trigger when false')}</MenuItem>
                <MenuItem value="3">{t('Trigger on change (either true or false)')}</MenuItem>
                </Field>
                {submitErrors &&
                  <FieldErrorRenderer field='trigger_condition' details={submitErrors.details} className={classes.fieldError} />}
              </Grid>
            </Grid>
          </Form>
          {submitSuccess &&
            <SnackbarContent className={classes.success} message={
              <span id="client-snackbar" className={classes.message}>{t("Configuration saved successfully")}</span>
            }
            />}
          {submitErrors && submitErrors.detail &&
            <SnackbarContent className={classes.error} message={
              <span>{submitErrors.detail && submitErrors.detail}</span>
            } />}
          {submitErrors && submitErrors.details && submitErrors.details.non_field_errors &&
            <SnackbarContent className={classes.error} message={
              <FieldErrorRenderer details={submitErrors.details} field={'non_field_errors'} />
            } />}
        </Paper>
      )}
  </Formik>
};

export default BooleanConfig;