export const eventsMap = {
  'Power on reset': 'Device',
  'Sensor fault': 'Alarm',
  'Battery low alarm': 'Alarm',
  'High alarm active for measurement': 'Alarm',
  'Low alarm active for measurement': 'Alarm',
  'No alarm for measurement': 'Alarm',
  'High pre-alarm active for measurement': 'Alarm',
  'Low pre-alarm active for measurement': 'Alarm',
  'Change of power input': 'User',
  'Change of working parameters': 'User',
  'Reset to defaults': 'User',
  'Wake up button pressed': 'User',
  'Sensor online': 'Connectivity_on',
  'Sensor offline': 'Connectivity_off',
  'Sensor offline with no values': 'Connectivity_off',
  'Sensor chip state disconnected':'Chip_state_problem',
  'Sensor chip state OK':'Chip_state_restored',
  'STAFF: sensor type changed': 'staff',
}

export const lastWordRegex= /\b(\w+)$/;
export const alarmRegex = /.*[\s].*?alarm.*?for measurement*/;