import React, { useState } from 'react';
import { CircularProgress, Button, makeStyles, Grid, TextField} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { requestAddNewRecipient, resolveAddNewRecipient, rejectAddNewRecipient } from '../../actions';
import { jwtTokenSelector, recipientAddIsLoadingSelector } from '../../selectors/userSelectors';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import _ from 'lodash';
import FieldErrorRenderer from '../SensorEdit/FieldErrorRenderer';
import { userService } from '../../services';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  loading: {
    margin: "auto",
  },
  flex: {
    display: 'flex'
  },
  submit: {
    marginTop: theme.spacing(2),
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  fieldError: {
    color: theme.palette.error.main
  },
  success: {
    color: green[600],
  }
}));

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

let initialValues = {
  email: '',
}

const AddRecipient = () => {
  const {t}= useTranslation();
  const classes= useStyles();
  
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitErrors, setSubmitErrors] = useState(null);

  const jwtToken = useSelector(jwtTokenSelector);
  const isLoading = useSelector(recipientAddIsLoadingSelector);

  const dispatch = useDispatch();

  const submitAddEmail = (values,actions) =>{
    dispatch(requestAddNewRecipient(values.email, jwtToken));
    
    userService.addNewEmailRecipient(values.email, jwtToken)
    .then((result)=>{
        dispatch(resolveAddNewRecipient(result));
        setSubmitSuccess(true);
        setSubmitErrors(null);
        actions.resetForm(initialValues);
      })
      .catch((err)=>{
        dispatch(rejectAddNewRecipient(err));
        setSubmitSuccess(false);
        setSubmitErrors(err);
        actions.setSubmitting(false);
      })
      actions.resetForm(initialValues);
  }

  return (<Formik
  initialValues={{...initialValues}}
  onSubmit={submitAddEmail}
  validationSchema={schema}
  >
  {({
    errors,
    values,
    touched,
    dirty,
    isSubmitting,
    handleSubmit,
    handleChange,
    setFieldTouched,
  })=>(
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Field
            type='text'
            label={t("Email Address")}
            name='email'
            component={TextField}
            value={values.email}
            onChange = {handleChange('email')}
            onKeyUp = {() => setFieldTouched('email')}
            fullWidth
          />
          {errors.email && touched.email? <div className={classes.fieldError}>
              {t(errors.email)} 
            </div>:''
          }
          {submitSuccess && 
            <div className={classes.success}>{t('Successfully added address')}</div>
          }
          {submitErrors && 
          <FieldErrorRenderer field='email' details={submitErrors.details} className={classes.fieldError}/>}
        </Grid>
        <Grid item xs={12}>
          <div className={classes.flex}>
          {isLoading
            ? <CircularProgress className={classes.loading} />
            : <Button
              type="submit"
              color="primary"
              variant="contained"
              className={classes.submit}
              disabled = {_.size(errors) < 1 ? false : true }
              fullWidth
            >
              {t('Add')}
            </Button>
          }</div>
        </Grid>
      </Grid>
    </Form>
  )}
  </Formik>

)}

export default AddRecipient;