import React from 'react';

import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  ledGreen: {
    margin: "0 auto",
    width: "24px",
    height: "24px",
    backgroundColor: "#4caf50",
    borderRadius: "50%",
    boxShadow: "rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 12px"
  },
  ledRed: {
    margin: "0 auto",
    width: "24px",
    height: "24px",
    backgroundColor: "#F00",
    borderRadius: "50%",
    boxShadow: "rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px"
  }
}));


const BooleanGauge = ({gauge_info, height, index, columns}) => {
  const classes = useStyles();

  const divStyle = {
      height: `${height}px`,
    };

  return (
      <Grid item id={`boolind${index}`} xs={12} sm={columns} style={divStyle}>
        <Typography variant="h6" align="center" gutterBottom>{gauge_info.name}</Typography>
        <Box className={gauge_info.bool_value === true ? classes.ledGreen : classes.ledRed} m={2}/>
      </Grid>
    );
}

export default BooleanGauge;
