import moment from "moment";
import i18n from 'i18next';
import { eventsMap, alarmRegex, lastWordRegex } from "../alarmDictionary";

export const exportToCSV = (
data,
separator = ',',
monthFirst = false
)=>{

  const s = separator;
  
  const CSVData = data.map((row) => {
    const prepareDates = Object.keys(row)
    .map((key,idx)=>{
      return key === "time"?
      
        moment.unix(Object.values(row)[idx] / 1000).format(monthFirst? `MM/DD/YYYY HH:mm:ss` : `DD/MM/YYYY HH:mm:ss`)
      
      :key === "event_type"?
      
        (eventsMap[alarmRegex.exec(Object.values(row)[idx])] === "Alarm" ?
        i18n.t(alarmRegex.exec(Object.values(row)[idx]))+" "+i18n.t(lastWordRegex.exec(Object.values(row)[idx])) 
        :i18n.t(Object.values(row)[idx]))

      :Object.values(row)[idx];
    })    
    return prepareDates;
  }).join('\n');
  
  
  const CSVHeader = Object.keys(data[0]).map( title=> i18n.t(title)).join(s)+"\n";
  
  return new Blob([CSVHeader,CSVData],{type:'text/csv'});
}
