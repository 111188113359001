import React, { useState } from 'react';
import { makeStyles, TextField, Button, Grid, Paper, SnackbarContent} from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { rejectLogin } from '../../actions';
import { userEmailSelector } from '../../selectors/userSelectors';
import { Formik, Field, Form } from 'formik';
import * as yup from 'yup';
import _ from 'lodash';
import ChangePasswordFormHeader from './ChangePasswordFormHeader';
import { userService } from '../../services';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  fieldError: {
    color: theme.palette.error.main
  },
  paper: {
    marginBottom: theme.spacing(2),
  },
  mg2: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  formControl: {
    display: 'flex'
  }
}));

const schema = yup.object().shape({
  old_password: yup.string().required('Old password is required'),
  new_password1: yup.string().required('New password is required').notOneOf([yup.ref('old_password')],"Old and new password need to be different"),
  new_password2: yup.string().required('Password confirmation required').oneOf([yup.ref('new_password1'), null], 'Passwords doesn\'t match').notOneOf([yup.ref('old_password')],"Old and new password need to be different")
});
let vals={old_password:'',new_password1:'',new_password2:''};

const ChangePassword = () => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitErrors, setSubmitErrors] = useState(null);
  const userEmail = useSelector(userEmailSelector);
  const { t } = useTranslation();
  
  const submitPasswordChange = (values,actions) => {
    userService.login(userEmail, values.old_password)
    .then((result)=>{
      userService.changePassword(
        result.token,values.new_password1,values.new_password2).then(()=>{
          setSubmitSuccess(true);
          setSubmitErrors(null);
          actions.resetForm(vals);
        }).catch((err)=>{
          setSubmitSuccess(false);
          setSubmitErrors(err);
          actions.setSubmitting(false);
        })
    }).catch((err)=>{
      dispatch(rejectLogin(err));
    });
  };

  return <Formik
        initialValues={{...vals}}
        onSubmit={submitPasswordChange}
        validationSchema={schema}        
        >
        {({
          errors,
          values,
          touched,
          dirty,
          isSubmitting,
          handleReset,
          handleChange,
          setFieldTouched,
        }) => (
            <Paper className={classes.paper}>
              <ChangePasswordFormHeader
              title={t("Change password")} errors={errors} 
              handleReset={handleReset} touched={touched} isSubmitting={isSubmitting} dirty={dirty} />
              <Form>
                <Grid container spacing={3} className={classes.root}>
                  <Grid item xs={12}>
                    <Field
                      type='password'
                      label={t("Old password")}
                      value={values.old_password}
                      onChange={handleChange('old_password')}
                      onKeyUp={()=>setFieldTouched('old_password')}
                      component={TextField}
                      inputProps={{
                        autoComplete: "old-password"
                      }}
                      fullWidth
                    />
                    {errors.old_password && touched.old_password  ? <div className={classes.fieldError}>
                      {t(errors.old_password)} 
                      </div>:''
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      type='password'
                      label={t("New password")}
                      value={values.new_password1}
                      onChange={handleChange('new_password1')}
                      onKeyUp={()=>setFieldTouched('new_password1')}
                      component={TextField}
                      inputProps={{
                        autoComplete: "new-password"
                      }}
                      fullWidth
                    />
                    {errors.new_password1 && touched.new_password1 ? <div className={classes.fieldError}>
                      {t(errors.new_password1)} 
                      </div>:''
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      type='password'
                      label={t("Validate password")}
                      value={values.new_password2}
                      onChange={handleChange('new_password2')}
                      onKeyUp={()=>setFieldTouched('new_password2')}
                      component={TextField}
                      inputProps={{
                        autoComplete: "new-password"
                      }}
                      fullWidth
                    />
                    {errors.new_password2 && touched.new_password2 ? <div className={classes.fieldError}>
                      {t(errors.new_password2)} 
                      </div>:''
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit" 
                      color="primary"
                      variant="contained"
                      fullWidth
                      disabled={ _.size(errors)<1 ? false :true }
                      >
                      {t('Change password')}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
              {submitSuccess &&
              <SnackbarContent className={classes.success} message={
                <span id="client-snackbar" className={classes.message}>{t("Password changed successfully")}</span>
              }
              />}
              {submitErrors && submitErrors.details &&
              <SnackbarContent className={classes.error} message={
                  _.map(submitErrors.details,(detail)=>{
                      return (_.map(detail,(error)=>{
                        return (<div>{t(error)}</div>)
                      }))
                    })
              }/>}
            </Paper>
          )}
      </Formik>
};

export default ChangePassword;