import React from 'react'
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Button} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const ConfirmDialog = ({
text = "Are you sure?",
open,
title,
fnAgree = ()=>{
  //foo
},
fnReject = ()=>{
  //foo
}
})=>{
  const {t} = useTranslation();
  
  const handleAgree = ()=>{
    fnAgree();
  }

  const handleReject = ()=>{
    fnReject();
  }

  return <Dialog
  open = {open}
  >
  {title && <DialogTitle>{t(title)}</DialogTitle>}
    <DialogContent>
      <DialogContentText>
        {text}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button color="primary" onClick={handleAgree}>
        {t("Ok")}
      </Button>
      <Button color="primary" autoFocus onClick={handleReject}>
        {t("Cancel")}
      </Button>
    </DialogActions>
  </Dialog>
}

export default ConfirmDialog;