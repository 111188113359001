import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import classNames from "classnames";
import IconButton from '@material-ui/core/IconButton';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectedSensor } from '../selectors/sensorSelectors';

const mainListItems = (t) => {

  return <>
    <ListItem button to={"/"} component={Link}>
      <ListItemIcon>
        <FontAwesomeIcon icon={["far", "home"]} size="lg" />
      </ListItemIcon>
      <ListItemText primary={t("Home")} />
    </ListItem>
    <ListItem button to={"/sensors/"} component={Link}>
      <ListItemIcon>
        <FontAwesomeIcon icon={["far", "upload"]} size="lg" />
      </ListItemIcon>
      <ListItemText primary={t("Sensors")} />
    </ListItem>
  </>
};

const displayEME = (ref_id,t) => (
  <>
    <Divider />
    <ListItem button to={`/sensors/${ref_id}`} component={Link}>
      <ListItemIcon>
        <FontAwesomeIcon icon={["far", "info-circle"]} size="lg" />
      </ListItemIcon>
      <ListItemText primary={t("Details")} />
    </ListItem>
    <ListItem button to={`/sensors/${ref_id}/edit`} component={Link}>
      <ListItemIcon>
        <FontAwesomeIcon icon={["far", "edit"]} size="lg" />
      </ListItemIcon>
      <ListItemText primary={t("Edit")} />
    </ListItem>
    <ListItem button to={`/sensors/${ref_id}/measurements`} component={Link}>
      <ListItemIcon>
        <FontAwesomeIcon icon={["far", "tachometer"]} size="lg" />
      </ListItemIcon>
      <ListItemText primary={t("Measurements")} />
    </ListItem>
    <ListItem button to={`/sensors/${ref_id}/events`} component={Link}>
      <ListItemIcon>
        <FontAwesomeIcon icon={["far", "bell"]} size="lg" />
      </ListItemIcon>
      <ListItemText primary={t("Events")} />
    </ListItem>
  </>
)

const drawerWidth = 240;

const styles = theme => ({
  drawerPaper: {
    zIndex: theme.zIndex.drawer,
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8),
    },
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
});

const SideBar = ({ open, onDrawerClose, classes, showEME = false }) => {
  const sensor = useSelector(selectedSensor)
  sensor ? showEME = true : showEME = false;

  const { t } = useTranslation();

  return <Drawer
    variant="permanent"
    classes={{
      paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose),
    }}
    open={open}
  >
    <div className={classes.toolbarIcon}>
      <IconButton onClick={onDrawerClose}>
        <FontAwesomeIcon icon={["far", "chevron-left"]} />
      </IconButton>
    </div>
    <Divider />
    <List>
      {mainListItems(t)}
      {showEME &&
        displayEME(sensor.ref_id,t)}
    </List>
  </Drawer>
};


SideBar.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onDrawerClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(SideBar);
