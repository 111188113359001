import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red'
import {useTranslation} from 'react-i18next'

const useStyles = makeStyles(theme => ({
  online: {
    backgroundColor: green[500],
  },
  offline: {
    backgroundColor: red[500],
  },
}));

const OnlineStatus = ({isOnline}) => {
  const classes = useStyles();
  const {t} = useTranslation();

  return <Chip
    label={isOnline ? t("Online") : t("Offline")}
    classes={{
      colorPrimary: classes.online,
      colorSecondary: classes.offline
    }}
    color={isOnline ? "primary" : "secondary"}
  />
};

export default OnlineStatus;
