import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import { Select, Switch, TextField } from 'formik-material-ui';

import * as yup from 'yup';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import EditFormHeader from "./EditFormHeader";
import { jwtTokenSelector } from "../../selectors/userSelectors";
import { useDispatch, useSelector } from "react-redux";
import { sensorService } from "../../services";
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { amber, green } from "@material-ui/core/colors";
import { updateSensorConfig } from "../../actions";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FieldErrorRenderer from "./FieldErrorRenderer";
import {useTranslation} from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  fieldError: {
    color: theme.palette.error.main
  },
  formControl: {
    minWidth: 200
  },
  paper: {
    marginBottom: theme.spacing(2),
  },
}));

const schema = yup.object().shape({
  sleep_seconds: yup.number().min(30).max(24 * 3600).required(),
  wakeup_times: yup.number().min(1).max(1000).required(),
  alarm_enable: yup.boolean(),
  alarm_delay: yup.number().min(0).max(3600).required(),
});

const SensorConfig = ({ sensorConfig, sensorRef }) => {
  const classes = useStyles();
  const jwtToken = useSelector(jwtTokenSelector);
  const dispatch = useDispatch();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitErrors, setSubmitErrors] = useState(null);
  const {t} = useTranslation();

  const submitConfig = (values, actions) => {
    sensorService.updateConfig(values, sensorRef, jwtToken)
      .then(() => {
        setSubmitSuccess(true);
        setSubmitErrors(null);
        actions.resetForm(values)
        dispatch(updateSensorConfig(sensorRef, values));
      })
      .catch((err) => {
        setSubmitSuccess(false);
        setSubmitErrors(err);
        actions.setSubmitting(false);
      }
      )
  };

  return <Formik
    initialValues={{ ...sensorConfig }}
    onSubmit={submitConfig}
    validationSchema={schema}
  >
    {({
        errors,
        touched,
        dirty,
        handleSubmit,
        isSubmitting,
        handleReset
      }) => (
      <Paper className={classes.paper}>
        <EditFormHeader
          errors={errors} handleReset={handleReset} handleSubmit={handleSubmit} isSubmitting={isSubmitting}
          title={t("Generic sensor configuration")} touched={touched} dirty={dirty}/>
        <Form>
          <Grid container spacing={3} className={classes.root}>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
              <InputLabel htmlFor="op-mode">{t("Operational Mode")}</InputLabel>
              <Field
                name="operational_mode"
                component={Select}
                inputProps={{
                  id: 'op-mode',
                }}
              >
                <MenuItem value={0}>{t("Normal")}</MenuItem>
                <MenuItem value={1}>{t("Always On")}</MenuItem>
              </Field>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Field
                type='text'
                label={t('Sleep seconds')}
                name='sleep_seconds'
                component={TextField}
                fullWidth
              />
              {submitErrors &&
              <FieldErrorRenderer field='sleep_seconds' details={submitErrors.details} className={classes.fieldError}/>}
            </Grid>
            <Grid item xs={12}>
              <Field
                type='text'
                label={t('Wakeup times')}
                name='wakeup_times'
                component={TextField}
                fullWidth
              />
              {submitErrors &&
              <FieldErrorRenderer field='wakeup_times' details={submitErrors.details} className={classes.fieldError}/>}
            </Grid>
            <Grid item xs={12}>
              <Field
                type='text'
                label={t('Alarm enable')}
                name='alarm_enable'
                render={({field, form}) => {
                  return <FormControlLabel control={<Switch form={form} field={field}/>} label={t('Alarm enabled')}/>
                }}
                fullWidth
              />
              {submitErrors &&
              <FieldErrorRenderer field='alarm_enable' details={submitErrors.details} className={classes.fieldError}/>}
            </Grid>
            <Grid item xs={12}>
              <Field
                type='text'
                label={t('Alarm delay')}
                name='alarm_delay'
                component={TextField}
                fullWidth
              />
              {submitErrors &&
              <FieldErrorRenderer field='alarm_delay' details={submitErrors.details} className={classes.fieldError}/>}
            </Grid>
          </Grid>
        </Form>
        {submitSuccess &&
        <SnackbarContent className={classes.success} message={
          <span id="client-snackbar" className={classes.message}>{t('Configuration saved successfully')}</span>
        }
        />}
        {submitErrors && submitErrors.detail &&
        <SnackbarContent className={classes.error} message={
          <span>{submitErrors.detail && submitErrors.detail}</span>
        }
        />}
      </Paper>
    )}
  </Formik>
};

export default SensorConfig;
