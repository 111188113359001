import moment from "moment";

export const EVENTS_ACTIONS = {
  EVENTS_REQUESTED: 'EVENTS_REQUESTED',
  EVENTS_RESOLVED: 'EVENTS_RESOLVED',
  EVENTS_REJECTED: 'EVENTS_REJECTED',
  CLEAR_EVENTS: 'CLEAR_EVENTS',
};

export const requestEvents = (
  startDate = new Date(),
  endDate = moment().subtract(15, 'days')
) => ({
  type: EVENTS_ACTIONS.EVENTS_REQUESTED,
  startDate,
  endDate
});

export const resolveEvents = (events) => ({
  type: EVENTS_ACTIONS.EVENTS_RESOLVED,
  events,
});

export const rejectEvents = (error)=>({
  type: EVENTS_ACTIONS.EVENTS_REJECTED,
  error,
})

export const clearEvents =()=> ({
  type: EVENTS_ACTIONS.CLEAR_EVENTS
});

