import { MEASUREMENTS_ACTIONS } from '../actions';
import moment from "moment";

const initialMeasurementsState = {
  measurements: [],
  startDate: new Date(),
  endDate: moment().subtract(15, 'days'),
  isLoading: false,
  error: null
};

const measurementsReducer = (state = initialMeasurementsState, action) => {
  switch (action.type) {
    case MEASUREMENTS_ACTIONS.MEASUREMENTS_REQUESTED:
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
        error: null,
        isLoading: true
      };
    case MEASUREMENTS_ACTIONS.MEASUREMENTS_RESOLVED:
      return {
        ...state,
        error: null,
        measurements: action.measurements,
        isLoading: action.hasMore,
      };
    case MEASUREMENTS_ACTIONS.MEASUREMENTS_REJECTED:
      return {
        ...state,
        error: action.error,
        measurements: [],
        isLoading: false,
      };
    case MEASUREMENTS_ACTIONS.CLEAR_MEASUREMENTS:
      return {
        ...initialMeasurementsState
      }
    case MEASUREMENTS_ACTIONS.MEASUREMENTS_NEXT_PAGE_RESOLVED:
      return {
        ...state,
        measurements: [...action.measurements, ...state.measurements],
        error: null,
        isLoading: action.hasMore,
      }
    case MEASUREMENTS_ACTIONS.MEASUREMENTS_NEXT_PAGE_REJECTED:
      return {
        ...state,
        measurements: [],
        error: action.error,
        isLoading: false,
      }
    default:
      return state;
  }
}

export default measurementsReducer;
