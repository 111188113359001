export const EVENT_CHART_ACTIONS = {
  TOGGLE_USER_EVENTS: 'TOGGLE_USER_EVENTS',
  TOGGLE_ALARM_EVENTS: 'TOGGLE_ALARM_EVENTS',
  TOGGLE_CONNECTIVITY_EVENTS: 'TOGGLE_CONNECTIVITY_EVENTS',
  TOGGLE_CHIP_STATE_EVENTS: 'TOGGLE_CHIP_STATE_EVENTS',
};

export const toggleUserEvents = () => {
  return {
    type: EVENT_CHART_ACTIONS.TOGGLE_USER_EVENTS
  }
}

export const toggleAlarmEvents = () => {
  return {
    type: EVENT_CHART_ACTIONS.TOGGLE_ALARM_EVENTS
  }
}

export const toggleConnectivityEvents = () => {
  return {
    type: EVENT_CHART_ACTIONS.TOGGLE_CONNECTIVITY_EVENTS
  }
}

export const toggleChipStateEvents = () => {
  return {
    type: EVENT_CHART_ACTIONS.TOGGLE_CHIP_STATE_EVENTS
  }
}