import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";
import { requestEvents } from '../../actions'
import MomentUtils from "@date-io/moment";
import moment from "moment";
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
  dtPicker: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  switches: {
    margin: theme.spacing(1),
  },
  switchLabel: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}))

const EventsConfigurator = () => {
  const [selectedStartDate, setSelectedStartDate] = useState(moment().subtract(15, 'days'));
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const {t} = useTranslation();
  // const [showAlarms, setShowAlarms] = useState(false);
  // const [showEvents, setShowEvents] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();

  return <>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Typography variant="h6">{t("Pick a date")}</Typography>
      <DateTimePicker
        ampm={false}
        label={t("Start Date")}
        value={selectedStartDate}
        onChange={setSelectedStartDate}
        className={classes.dtPicker}
        maxDate={new Date()}
        fullWidth
      />
      <DateTimePicker
        ampm={false}
        label={t("End Date")}
        value={selectedEndDate}
        onChange={setSelectedEndDate}
        className={classes.dtPicker}
        maxDate={new Date()}
        fullWidth
      />
      <Button variant="contained" color="primary" className={classes.button} size="large" fullWidth
        onClick={() => dispatch(requestEvents(selectedStartDate, selectedEndDate))}>
        {t("Load data")}</Button>

    </MuiPickersUtilsProvider>
  </>

}

export default EventsConfigurator;