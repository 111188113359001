import React from 'react';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectedSensor } from '../../selectors/sensorSelectors';
import {useTranslation} from 'react-i18next';

const SensorName =()=>{
  const {t} = useTranslation();

  const sensorDetail = useSelector(selectedSensor);

  return <Typography variant="h4" gutterBottom component="h2">{t('Sensor')} {sensorDetail.name || sensorDetail.id}</Typography>
  
}

export default SensorName;