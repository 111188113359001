import React, {useState} from 'react';
import {Formik, Field, Form} from 'formik';
import {TextField} from 'formik-material-ui';
import * as yup from 'yup';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/core';
import EditFormHeader from "./EditFormHeader";
import {jwtTokenSelector} from "../../selectors/userSelectors";
import {useDispatch, useSelector} from "react-redux";
import {sensorService} from "../../services";
import SnackbarContent from '@material-ui/core/SnackbarContent';
import green from "@material-ui/core/colors/green";
import {updateSensorName} from "../../actions";
import FieldErrorRenderer from "./FieldErrorRenderer";
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  fieldError: {
    color: theme.palette.error.main
  },
  paper: {
    marginBottom: theme.spacing(2),
  }
}));

const schema = yup.object().shape({
  name: yup.string(),
});


const SensorName = ({name, sensorRef}) => {
  const classes = useStyles();
  const jwtToken = useSelector(jwtTokenSelector);
  const dispatch = useDispatch();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitErrors, setSubmitErrors] = useState(null);
  const {t} = useTranslation();

  const submitConfig = (values, actions) => {
    const updatedName = values.name === '' ? null : values.name
    sensorService.updateName(updatedName, sensorRef, jwtToken)
      .then(() => {
        setSubmitSuccess(true);
        setSubmitErrors(null);
        actions.resetForm(values)
        dispatch(updateSensorName(sensorRef, values.name));
      })
      .catch((err) => {
          setSubmitSuccess(false);
          setSubmitErrors(err);
          actions.setSubmitting(false);
        }
      )
  };

  return <Formik
    initialValues={{name: name == null ? '' : name}}
    onSubmit={submitConfig}
    validationSchema={schema}
  >
    {({
        errors,
        touched,
        dirty,
        handleSubmit,
        isSubmitting,
        handleReset
      }) => (
      <Paper className={classes.paper}>
        <EditFormHeader
          errors={errors} handleReset={handleReset} handleSubmit={handleSubmit} isSubmitting={isSubmitting}
          title={t("Sensor name")} touched={touched} dirty={dirty}/>
        <Form>
          <Grid container spacing={3} className={classes.root}>
            <Grid item xs={12}>
              <Field
                type='text'
                label={t('Name')}
                name='name'
                component={TextField}
                fullWidth
              />
              {submitErrors &&
              <FieldErrorRenderer field='name' details={submitErrors.details} className={classes.fieldError}/>}
            </Grid>
          </Grid>
        </Form>
        {submitSuccess &&
        <SnackbarContent className={classes.success} message={
          <span id="client-snackbar" className={classes.message}>{t("Name saved successfully")}</span>
        }
        />}
        {submitErrors && submitErrors.detail &&
        <SnackbarContent className={classes.error} message={
          <span>{submitErrors.detail && submitErrors.detail}</span>
        }
        />}
      </Paper>
    )}
  </Formik>
};

export default SensorName;
