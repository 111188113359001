import React from 'react'
import { Icon, Tooltip } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {useTranslation} from 'react-i18next';
const VerifiedIcon =({verified})=>{
  const {t} = useTranslation();
  return <Tooltip title={verified ? t("Verified"):t("Unverified")} placement="top">
    <Icon>
      <FontAwesomeIcon size="sm" icon={["far",verified? "check": "times"]} />
    </Icon>
  </Tooltip>
}

export default VerifiedIcon;