import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SensorBaseDetails from '../components/SensorDetail/SensorBaseDetails';
import MultiGauge from "../components/SensorDetail/Gauges/MultiGauge";
import Divider from "@material-ui/core/Divider";
import ChartPanel from "../components/SensorDetail/Charts/ChartPanel";
import { requestSensor} from "../actions";
import {useTranslation} from 'react-i18next';
import {NotFound} from '../common/NotFound';
import SensorName from '../components/SensorDetail/SensorName';
import { selectedSensorExists, selectedSensorLoading, lastSensorUpdate } from '../selectors/sensorSelectors';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
  },
  paperHeader: {
    fontSize: 18,
    padding: theme.spacing(2),
  },
  loading: {
    margin: "auto",
  }
}));

const SensorDetailPage = ({ match }) => {
  const isLoading = useSelector(selectedSensorLoading);
  const exists = useSelector(selectedSensorExists);
  
  const lastSelectedSensorUpdate = useSelector(lastSensorUpdate);
  const shouldFetch = lastSelectedSensorUpdate === null || moment().subtract(15, 'minutes').isAfter(lastSelectedSensorUpdate);

  const classes = useStyles();
  const {t} = useTranslation();
  
  const sensorRefId = match && match.params && match.params.id;
  const dispatch = useDispatch();
  
  useEffect(() => {
    if(!exists || shouldFetch){
      dispatch(requestSensor(sensorRefId));
    }    
  },[dispatch,exists,sensorRefId,shouldFetch])

  const render_sensor_details = () =>{
    return<>
    <Grid className={classes.root} container spacing={3}>
      <Grid item xs={12} md={8}>
        <SensorName ref_id={sensorRefId}/>
      </Grid>
    </Grid>

    <Grid className={classes.root} container spacing={3}>
      <Grid item xs={12} md={8}>
        <SensorBaseDetails ref_id={sensorRefId}/>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper>
          <Typography className={classes.paperHeader} variant="h4">{t('Current Status')}</Typography>
          <Divider />
          <MultiGauge sensor_id={sensorRefId} />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <ChartPanel sensorId={sensorRefId} />
        </Paper>
      </Grid>
    </Grid>
    </>  
  }

  if(isLoading)
    return <CircularProgress className={classes.loading}/>

  if(exists)
    return render_sensor_details();

  return <NotFound/>
}

export default SensorDetailPage;
