import {
  post,
  get,
  put,
  delete_method,
  get_absolute,
} from './baseRequestService';

export default {
  login: (email, password) => {
    return post('/v1/users/login/', null, {
      email,
      password
    });
  },

  changePassword:(jwtToken, new_password1, new_password2)=>{
    return post('/v1/users/password/change/',jwtToken,{
      new_password1,
      new_password2
    });
  },

  getUserDetails: (jwtToken) => {
    return get('/v1/users/user/', jwtToken);
  },

  register: (registerInfo) => {
    return post('/v1/users/', null, registerInfo);
  },

  resetPassword: (email)=>{
    return post('/v1/users/password/reset/',null,{
      email
    })
  },

  getEmailRecipients : (jwtToken)=>{
    return get('/v1/users/emails/',jwtToken);
  },

  getEmailRecipientsNextPage : (url,jwtToken) =>{
    return get_absolute(url,jwtToken);
  },

  addNewEmailRecipient : (email, jwtToken)=>{
    return post('/v1/users/emails/',jwtToken,{
      email,
    })
  },

  toggleEmailPrimaryRecipient : (id, primary,jwtToken) =>{
    return put(`/v1/users/emails/${id}/`, jwtToken,{
      primary
    })
  },

  deleteEmailRecipient : (id,jwtToken)=>{
    return delete_method(`/v1/users/emails/${id}/`, jwtToken);
  }
}