import moment from "moment";

export const MEASUREMENTS_ACTIONS = {
  MEASUREMENTS_REQUESTED: 'MEASUREMENTS_REQUESTED',
  MEASUREMENTS_RESOLVED: 'MEASUREMENTS_RESOLVED',
  MEASUREMENTS_REJECTED: 'MEASUREMENTS_REJECTED',
  CLEAR_MEASUREMENTS: 'CLEAR_MEASUREMENTS',
  MEASUREMENTS_NEXT_PAGE_REQUESTED: 'MEASUREMENTS_NEXT_PAGE_REQUESTED',
  MEASUREMENTS_NEXT_PAGE_RESOLVED: 'MEASUREMENTS_NEXT_PAGE_RESOLVED',
  MEASUREMENTS_NEXT_PAGE_REJECTED: 'MEASUREMENTS_NEXT_PAGE_REJECTED',
};

export const requestMeasurements = (
  startDate = new Date(),
  endDate = moment().subtract(15, 'days')
) => ({
  type: MEASUREMENTS_ACTIONS.MEASUREMENTS_REQUESTED,
  startDate,
  endDate
});

export const resolveMeasurements = (measurements, hasMore) => ({
  type: MEASUREMENTS_ACTIONS.MEASUREMENTS_RESOLVED,
  measurements,
  hasMore,
});

export const rejectMeasurements = (error) => {
  return {
    type: MEASUREMENTS_ACTIONS.MEASUREMENTS_REJECTED,
    error,
  };
};

export const clearMeasurements = () => ({
  type: MEASUREMENTS_ACTIONS.CLEAR_MEASUREMENTS
});

export const requestNextMeasurements = (nextURL) => ({
  type: MEASUREMENTS_ACTIONS.MEASUREMENTS_NEXT_PAGE_REQUESTED,
  nextURL,
});

export const resolveNextMeasurements = (measurements, hasMore) => ({
  type: MEASUREMENTS_ACTIONS.MEASUREMENTS_NEXT_PAGE_RESOLVED,
  measurements,
  hasMore,
});

export const rejectNextMeasurements = (error)=>({
  type: MEASUREMENTS_ACTIONS.MEASUREMENTS_NEXT_PAGE_REJECTED,
  error,
})