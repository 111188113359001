import Typography from "@material-ui/core/Typography";
import React from "react";

const renderFieldErrors = (errors, className) => errors.map((error, index) =>
  <Typography key={index} className={className}>{error}</Typography>
)

const FieldErrorRenderer = ({field, details, className}) => {
  if (details == null || !(field in details)) return null;


  return <>
    {renderFieldErrors(details[field], className)}
  </>
}

export default FieldErrorRenderer;
