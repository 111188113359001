import React, { useState } from 'react';
import Typography from "@material-ui/core/Typography";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from "@material-ui/core/Switch";
import { useDispatch } from "react-redux";
import { requestMeasurements, requestEvents } from "../../../actions";
import { toggleAlarmEvents, toggleUserEvents, toggleConnectivityEvents, toggleChipStateEvents } from '../../../actions';
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { showAlarmEventsSelector, showUserEventsSelector, showConnectivityEventsSelector, showChipStateEventsSelector } from '../../../selectors/eventChartSelector';
import { useSelector } from "react-redux";
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
  dtPicker: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  switches: {
    margin: theme.spacing(1),
  },
  switchLabel: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}))

const ChartConfigurator = ({ include_events = false, show_switches = false }) => {
  const [selectedStartDate, setSelectedStartDate] = useState(moment().subtract(15, 'days'));
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  // const [showAlarms, setShowAlarms] = useState(false);
  // const [showEvents, setShowEvents] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();

  const {t} = useTranslation();

  const showUserEvents = useSelector(showUserEventsSelector);
  const showAlarmEvents = useSelector(showAlarmEventsSelector);
  const showConnectivityEvents = useSelector(showConnectivityEventsSelector);
  const showChipStateEvents = useSelector(showChipStateEventsSelector);

  const setShowAlarms = () => {
    dispatch(toggleAlarmEvents());
  }

  const setShowUserEvents = () => {
    dispatch(toggleUserEvents());
  }

  const setShowConnectivityEvents = () => {
    dispatch(toggleConnectivityEvents());
  }

  const setShowChipStateEvents = () => {
    dispatch(toggleChipStateEvents());
  }

  return <>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Typography variant="h6">{t('Pick a date')}</Typography>
      <DateTimePicker
        ampm={false}
        label={t("Start Date")}
        value={selectedStartDate}
        onChange={setSelectedStartDate}
        className={classes.dtPicker}
        fullWidth
        maxDate={new Date()}
      />
      <DateTimePicker
        ampm={false}
        label={t("End Date")}
        value={selectedEndDate}
        onChange={setSelectedEndDate}
        className={classes.dtPicker}
        fullWidth
        maxDate={new Date()}
      />
      <Button variant="contained" color="primary" className={classes.button} size="large" fullWidth
        onClick={() => {
          dispatch(requestMeasurements(selectedStartDate, selectedEndDate));
          include_events && dispatch(requestEvents(selectedStartDate, selectedEndDate));
        }}>
        {t('Load data')}</Button>

      {show_switches &&
        <FormControl component="fieldset" className={classes.switches}>
          <FormLabel component="legend" className={classes.switchLabel}>{t("Finetune chart")}</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={showAlarmEvents}
                  onChange={() => { setShowAlarms() }}
                  value="Show alarms"
                  color="primary"
                />
              }
              label={t("Show alarms")}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={showUserEvents}
                  onChange={() => { setShowUserEvents() }}
                  value="Show user Events"
                  color="primary"
                />
              }
              label={t("Show user events")}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={showConnectivityEvents}
                  onChange={() => { setShowConnectivityEvents() }}
                  value="Show connectivity Events"
                  color="primary"
                />
              }
              label={t("Show connectivity events")}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={showChipStateEvents}
                  onChange={() => { setShowChipStateEvents() }}
                  value="Show Chip State Events"
                  color="primary"
                />
              }
              label={t("Show chip state events")}
            />
          </FormGroup>
        </FormControl>
      }
    </MuiPickersUtilsProvider>
  </>
};

export default ChartConfigurator;
