import React from 'react';
import Grid from "@material-ui/core/Grid";
import {Paper} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useTranslation} from "react-i18next";
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    height: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  widget: {
    margin: theme.spacing(1),
  },
  metric: {
    fontSize: "2.5em"
  },
  description: {}
}));

const DashboardPanel = ({value, description, error}) => {
  const classes = useStyles();
  return <Grid item xs={6} sm={3}>

    <Paper className={classes.paper}>
      {error != null
        ? <Typography className={classes.error}>Error while fetching data</Typography>
        : value == null
          ? <CircularProgress/>
          : <>
        <Typography className={classes.metric}>{value}</Typography>
        <Typography className={classes.description}>{description}</Typography>
        </>}
    </Paper>
  </Grid>
}


const DashboardPanels = ({sensors, online, alarm, battery_low, error}) => {
  const classes = useStyles();

  const {t} = useTranslation();
  return <div className={classes.root}>
    <Grid container spacing={3}>
      <DashboardPanel value={sensors} description={t("Sensors")} error={error}/>
      <DashboardPanel value={online} description={t("Online")} error={error}/>
      <DashboardPanel value={alarm} description={t("With alarm")} error={error}/>
      <DashboardPanel value={battery_low} description={t("With low battery")} error={error}/>
    </Grid>
  </div>
};

export default DashboardPanels;
