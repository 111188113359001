import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { setSensorSelected } from '../actions';
import { selectedSensor } from '../selectors/sensorSelectors';
import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';
import Divider from "@material-ui/core/Divider";
// import MeasurementDates from '../components/SensorMeasurements/MeasurementDates';
import ChartConfigurator from '../components/SensorDetail/Charts/ChartConfigurator'
import MeasurementsPanel from '../components/SensorMeasurements/MeasurementsPanel'
import ExportMonthlyData from '../components/SensorMeasurements/ExportMonthlyData';
import {useTranslation} from 'react-i18next';
import {NotFound} from '../common/NotFound';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  paperHeader: {
    fontSize: 18,
    padding: theme.spacing(2),
  },
}));

const SensorMeasurementsPage = ({ match }) => {
  const sensorRefId = match && match.params && match.params.id;
  const dispatch = useDispatch();
  dispatch(setSensorSelected(sensorRefId));
  const sensorDetail = useSelector(selectedSensor);
  const {t} = useTranslation();

  const classes = useStyles();

  if (sensorDetail == null) {
    return <NotFound text={"Sensor not found"}/>
  }
  return <>
    <Typography variant="h4" gutterBottom component="h2">{t('Measurements of')} {sensorDetail.name || sensorDetail.id}</Typography>
    <Grid className={classes.root} container spacing={3}>
      <Grid item xs={12} md={4} lg={4} >
        <Grid className={classes.root} container spacing={3}>
          <Grid item xs={12} md={12} lg={12} >
            <Paper className={classes.paper}>
              <ChartConfigurator />
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={12} >
            <Paper className={classes.paper}>
              <ExportMonthlyData/>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      
      <Grid item xs={12} md={8} lg={8}>
        <Paper className={classes.paper}>
          <Typography className={classes.paperHeader} variant="h4">{t('Sensor readings')}</Typography>
          <Divider />
          <MeasurementsPanel />
        </Paper>
      </Grid>
    </Grid>
  </>
}

export default SensorMeasurementsPage;