import {SETTINGS_ACTIONS} from '../actions/settingsActions';

const initialSettingsState = {
  isRefreshEnabled: false,
  isApplicationActive: null,
  refreshRate: 60000,
}

const settingsReducer = (state=initialSettingsState,action)=>{
  switch(action.type){
    case SETTINGS_ACTIONS.SET_REFRESH:
      return{
        ...state,
        isRefreshEnabled: action.refresh,
      };

    case SETTINGS_ACTIONS.SET_ACTIVE:
      return{
        ...state,
        isApplicationActive:action.active,
      }
    
    default:
      return{
        ...state,
      }
  }
}

export default settingsReducer;