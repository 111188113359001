import {useEffect,useRef} from 'react';

export const useInterval = (callback,delay)=>{
  const savedCallback = useRef();
  useEffect(()=>{
    savedCallback.current = callback;
  },[callback]);

  useEffect(()=>{
    function tick(){
      savedCallback.current();
    }
    if(delay !== null){
      let rt = setInterval(tick,delay);
      return ()=> clearInterval(rt);
    }
  },[delay]);
}