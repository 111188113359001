import React from 'react';
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useSelector } from "react-redux";
import { measurementsSelector, measurementsLoadingSelector, measurementsErrorsSelector } from "../../selectors/measurementsSelectors";
import keys from 'lodash/keys';
import filter from 'lodash/filter'
import MeasurementsTable from './MeasurementsTable'
import { Button } from '@material-ui/core';
import {exportToCSV} from '../../common/CSVUtils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useTranslation} from 'react-i18next';
import Constants from '../../common/constants';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "400px",
  },
  chartContainer: {
    display: "flex",
  },
  loading: {
    margin: "auto",
  },
  configurator: {
    padding: theme.spacing(2)
  }
}));

const MeasurementsPanel = () => {
  const isLoading = useSelector(measurementsLoadingSelector);
  const classes = useStyles();
  const {t} = useTranslation();
  const measurements = useSelector(measurementsSelector);
  const emptyMeasurements = measurements == null || measurements.length === 0;
  const error = useSelector(measurementsErrorsSelector);
  const measurement_names = emptyMeasurements ? [] : filter(keys(measurements[0]), x => x !== 'time');

  const render_measurements_panel = () => {
    if (isLoading)
      return <CircularProgress className={classes.loading} />;
    else if (!isLoading && emptyMeasurements && error)
      return <Typography className={classes.loading}>{t(error.error ? error.error : "Error while loading measurements")}</Typography>;
    else if (!isLoading && emptyMeasurements)
      return <Typography className={classes.loading}>{t("No measurements loaded")}</Typography>
    else if (!isLoading && !emptyMeasurements){
      
      if (measurement_names.includes(Constants.DISCOMFORT_INDEX_MEASUREMENT_TYPE)){
        measurements.map((mes)=>{
          mes.discomfort = Constants.getDIDescription(mes.discomfort);
          return mes;
        })
      }
      return <MeasurementsTable measurements={measurements} measurement_names={measurement_names} />
    }
  }

  const exportCSV = (measurements)=>{    
    let csvURL = window.URL.createObjectURL(exportToCSV(measurements));
    let tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', `measurements.csv`);
    document.body.appendChild(tempLink);
    tempLink.click();
  }

  return (
    <Grid className={classes.configurator}>
      <Button disabled= {emptyMeasurements} variant="contained" color="primary" className={classes.button} size="large" fullWidth onClick={() => { exportCSV(measurements) }}>
        <FontAwesomeIcon icon={["far", "download"]} />
        &nbsp;{t('Export to CSV')}
      </Button>
      {render_measurements_panel()}
    </Grid>
  )
  

}

export default MeasurementsPanel;