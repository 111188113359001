import React from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {withStyles} from "@material-ui/core";

const styles = theme => ({
  primary: {
    color: theme.palette.primary.main,
  },
})

const BatteryAlarmIcon = ({battery_alarm, classes}) => {
  if (battery_alarm === true)
    return <Tooltip title="Low Battery" placement="top">
              <span className="tooltip-primary">
                <FontAwesomeIcon className={classes.primary} icon={["far", "exclamation-triangle"]}/>
              </span>
    </Tooltip>

  return null;
}

export default withStyles(styles)(BatteryAlarmIcon);
