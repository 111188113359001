import React from 'react';
import { makeStyles, AppBar, Typography, Toolbar, IconButton, LinearProgress } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { amber } from '@material-ui/core/colors';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }, grow: {
    flexGrow: 1,
  },
  default: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main)
  },
  changed: {
    backgroundColor: amber[500],
    color: theme.palette.getContrastText(amber[500])
  },
  errors: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.getContrastText(theme.palette.error.main),
  },
  tooltipText:{
    fontSize: '12px',
  }
}
));

const NotifyOnlineOfflineHeader = ({
  title,
  errors,
  handleSubmit,
  handleReset,
  isSubmitting,
  dirty})=>{

    const classes = useStyles();
    const {t} = useTranslation();
    let headerClass = classes.default;
    if(!isEmpty(errors)) headerClass = classes.errors;
    else if(dirty) headerClass = classes.changed;
    return <>
    <AppBar className={headerClass} position='relative'>
      <Toolbar>
        <Typography variant='h6'>
          {title}
          {isEmpty(errors)? dirty ? ' - '+t('has changes') : '' : ' - '+t('has errors')}
        </Typography>
        <div className={classes.grow}/>
        <IconButton disabled={isSubmitting || !dirty} onClick={handleSubmit}
                    className={headerClass}>
          <FontAwesomeIcon size="sm" icon={["far", "save"]}/>
        </IconButton>
        <IconButton disabled={isSubmitting || !dirty} onClick={handleReset}
                    className={headerClass}>
          <FontAwesomeIcon size="xs" icon={["far", "undo"]}/>
        </IconButton>
      </Toolbar>
    </AppBar>
    {isSubmitting && <LinearProgress/>}
    </>
};

export default NotifyOnlineOfflineHeader;