import React, { useEffect } from 'react';
import ReactTable from 'react-table';
import moment from 'moment';
import { clearEvents } from '../../actions';
import { useDispatch } from "react-redux";
import {useTranslation} from 'react-i18next';
import {alarmRegex,eventsMap,lastWordRegex} from '../../alarmDictionary'

const EventsTable = ({ events }) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  useEffect(() => {
    return () => {
      dispatch(clearEvents());
    }
  });
  
  const columns = [
    {
      id: 'Datetime',
      Header: t('Event Date & Time'),
      accessor: 'time',
      Cell: row => (
        <>{moment.unix(row.value / 1000).format("DD/MMM/YYYY HH:mm:ss")}</>
      )
    },
    {
      id: 'Event',
      Header: t('Event'),
      accessor: 'event_type',
      Cell: row =>(
        <>
        {
          eventsMap[alarmRegex.exec(row.value)] === 'Alarm'? t(alarmRegex.exec(row.value))+" "+t(lastWordRegex.exec(row.value)) : t(row.value)
        }
        </>
      )
    }
  ]
  return (
    <ReactTable
      data={events}
      columns={columns}
      defaultSorted={[
            {
              id: "Datetime",
              desc: true
            }
          ]}
      className="-striped -highlight"
      nextText={t("Next")}
      previousText={t("Previous")}
      pageText={t("Page")}
      rowsText={t("rows")}
      ofText={t("of")}
    />

  )
}



export default EventsTable;