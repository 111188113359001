import { call, put, takeLatest, select } from 'redux-saga/effects';

import * as actions from '../actions';
import { dashboardService } from '../services';
import {jwtTokenSelector} from '../selectors/userSelectors';


export function* get_dashboard(action) {
  try {
    const jwtToken = yield select(jwtTokenSelector);
    const data = yield call(dashboardService.getDashboard, jwtToken);
    yield put(actions.resolveDashboard(data));
  } catch (e) {
    yield put(actions.rejectDashboard(e));
    if (e.result && e.result === "unauthorized")
      yield put(actions.tokenExpired());
  }
}

export default function* dashboardSaga() {
  // takeLatest retrieves the latest action that matches the first argument
  // (cancels all other pending) and creates a saga given by the second argument
  // we can choose to pass more args which will be passed to the saga in addition
  // to the incoming action
  yield takeLatest(actions.DASHBOARD_ACTIONS.DASHBOARD_REQUESTED, get_dashboard);
}
