import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import userReducer from './userReducer';
import sensorReducer from './sensorReducer';
import measurementsReducer from "./measurementReducer";
import dashboardReducer from "./dashboardReducer";
import eventsReducer from './eventsReducer';
import eventChartReducer from './eventChartReducer';
import settingsReducer from './settingsReducer';

export default (history) => combineReducers({
  router: connectRouter(history),
  user: userReducer,
  sensors: sensorReducer,
  measurements: measurementsReducer,
  dashboard: dashboardReducer,
  events: eventsReducer,
  eventChart: eventChartReducer,
  settings: settingsReducer,
});
