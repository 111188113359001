import React from 'react';
// import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from "@material-ui/core/IconButton";
import { useDispatch } from 'react-redux';
import { requestLogout, clearDashboard, clearSensorList } from '../actions';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function SimpleMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleLogout(event) {
    dispatch(clearDashboard());
    dispatch(clearSensorList());
    dispatch(requestLogout());
  }

  return (
    <div>
      <IconButton color="inherit" onClick={handleClick}>
        <FontAwesomeIcon size="sm" icon={["far", "user"]} />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
      >

        <MenuItem button to={"/settings"} component={Link}>{t("Settings")}</MenuItem>
        <MenuItem onClick={handleLogout} button>{t("Logout")}</MenuItem>
      </Menu>
    </div>
  );
}
