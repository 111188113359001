import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import {withStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const styles = theme => ({
  error: {
    color: theme.palette.error.light,
  },
})

const AlarmIcon = ({alarm, classes}) => {
  if (alarm)
    return <Tooltip title="Alarm" placement="top">
              <span className="tooltip-primary">
                <FontAwesomeIcon className={classes.error} icon={["far", "exclamation-triangle"]}/>
              </span>
    </Tooltip>

  return null;
}

export default withStyles(styles)(AlarmIcon);
