import React from 'react';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {selectedSensor} from "../selectors/sensorSelectors";
import Grid from "@material-ui/core/Grid";
import SensorConfig from "../components/SensorEdit/SensorConfig";
import SensorName from "../components/SensorEdit/SensorName";
import MeasurementConfig from "../components/SensorEdit/MeasurementConfig";
import DiscomfortMeasurementConfig from "../components/SensorEdit/DiscomfortMeasurementConfig";
import BooleanConfig from "../components/SensorEdit/BooleanConfig";
import {setSensorSelected} from "../actions";
import {useTranslation} from 'react-i18next';
import {NotFound} from '../common/NotFound';
import SensorOfflineOnline from '../components/SensorEdit/SensorOfflineOnline';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
  },
  paperHeader: {
    fontSize: 18,
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
  }
}));

const SensorEditPage = ({match}) => {
  const sensorRefId = match && match.params && match.params.id;
  const dispatch = useDispatch();
  dispatch(setSensorSelected(sensorRefId));
  const sensorDetail = useSelector(selectedSensor);
  const {t} = useTranslation();

  const classes = useStyles();

  if (sensorDetail == null)
    return <NotFound text={"Sensor not found"}/>

  return <>
    <Typography variant="h4" gutterBottom component="h2">{t('Edit Sensor')} {sensorDetail.name || sensorDetail.id}</Typography>
    <Grid className={classes.root} container spacing={3}>
      <Grid item xs={12} md={6} container alignItems="stretch" direction="column">
        <SensorName name={sensorDetail.name} sensorRef={sensorDetail.ref_id}/>
        <SensorConfig sensorConfig={sensorDetail.configuration} sensorRef={sensorDetail.ref_id}/>
        <SensorOfflineOnline sensorRef={sensorDetail.ref_id} online={sensorDetail.notify_when_online} offline={sensorDetail.notify_when_offline}/>
      </Grid>
      <Grid item xs={12} md={6} container alignItems="stretch" direction="column">
        {
          sensorDetail.measurement_configs.map((config, idx) =>
            {            
            if(config.measurement_type === "discomfort"){
              return <DiscomfortMeasurementConfig measurementConfig={config} key={idx} sensorRef={sensorDetail.ref_id}/>
            }
            if(config.data_type === "Boolean"){
              return <BooleanConfig measurementConfig={config} key={idx} sensorRef={sensorDetail.ref_id}/>
            } 
            return <MeasurementConfig measurementConfig={config} key={idx} sensorRef={sensorDetail.ref_id}/>
            })
        }
      </Grid>
    </Grid>
  </>
}

export default SensorEditPage;
