import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {useTranslation} from 'react-i18next'
import Constants from '../../common/constants';
import DiscomfortIcon from '../SensorDetail/Gauges/DiscomfortIcon';

const MeasurementList = ({ values }) => {
  const {t} = useTranslation();
  
  return <>
    {values.length > 0 && _.map(values, (value, i) =>{
       return value.measurement_type !== Constants.DISCOMFORT_INDEX_MEASUREMENT_TYPE ? <span key={i}>{`${t(value.measurement_type)}: ${value.value}${value.units}`}<br /></span>
       : <DiscomfortIcon key={i} value={value.value} small />
        })}
  </>;
};

MeasurementList.propTypes = {
  values: PropTypes.array.isRequired,
};

export default MeasurementList;