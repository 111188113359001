import { get, get_absolute } from './baseRequestService';
import moment from "moment";

export default {
  getMeasurements: (sensorRef, startDate, endDate, jwtToken, page = 1) => {
    startDate = encodeURIComponent(moment(startDate).format());
    endDate = encodeURIComponent(moment(endDate).format());
    return get(`/v1/sensors/${sensorRef}/measurements/?fromdatetime=${startDate}&todatetime=${endDate}&page=${page}`, jwtToken);
  },

  getNextMeasurements: (url, jwtToken) => {
    return get_absolute(url, jwtToken);
  },

};
