import {get, get_absolute, post, put} from './baseRequestService';

export default {
  getSensorList: (jwtToken, page=1) => {
    return get(`/v1/sensors/`, jwtToken);
  },

  getGauges: (sensorRef, jwtToken) => {
    return get(`/v1/sensors/${sensorRef}/gauges`, jwtToken);
  },

  getSingleSensor: (sensorRef, jwtToken) =>{
    return get(`/v1/sensors/${sensorRef}/`,jwtToken);
  },

  updateConfig: (configOptions, sensorRef, jwtToken) => {
    return post(`/v1/sensors/${sensorRef}/configuration/`, jwtToken, configOptions);
  },

  updateName: (newName, sensorRef, jwtToken) => {
    return put(`/v1/sensors/${sensorRef}/`, jwtToken, {name: newName});
  },

  updateNotifySettings: (notifyOptions, sensorRef,jwtToken)=>{
    return put(`/v1/sensors/${sensorRef}/`,jwtToken, notifyOptions);
  },

  updateMeasurementConfig: (configOptions, measurementConfigRef, sensorRef, jwtToken) => {
    return put(`/v1/sensors/${sensorRef}/measurements/config/${measurementConfigRef}/`, jwtToken, configOptions);
  },

  getNextPage: (page_url, jwtToken) => {
    return get_absolute(page_url, jwtToken);
  },
};
