import { library } from '@fortawesome/fontawesome-svg-core';
// import { faExclamationTriangle } from '@fortawesome/fontawesome-pro';
import { far, faExclamationTriangle, faCloud, faSlash, faWifi1, faWifi2, faWifi, faWifiSlash,
  faBatteryEmpty, faBatteryQuarter, faBatteryHalf, faBatteryThreeQuarters, faBatteryFull,
  faEdit, faHome, faUpload, faUser, faBars, faChevronLeft, faLockAlt, faSave, faUndo, faSync,
} from '@fortawesome/pro-regular-svg-icons';
import { fab, faUsb } from '@fortawesome/free-brands-svg-icons';

library.add(far, faExclamationTriangle, faCloud, faSlash, faWifi1, faWifi2, faWifi, faWifiSlash,
  faBatteryEmpty, faBatteryQuarter, faBatteryHalf, faBatteryThreeQuarters, faBatteryFull,
  fab, faUsb, faEdit, faHome, faUpload, faUser, faBars, faChevronLeft, faLockAlt, faSave, faUndo, faSync,
);
