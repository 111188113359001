export const USER_ACTIONS = {
  LOGIN_REQUESTED : 'LOGIN_REQUESTED',
  LOGIN_RESOLVED : 'LOGIN_RESOLVED',
  LOGIN_REJECTED : 'LOGIN_REJECTED',
  TOKEN_EXPIRED : 'TOKEN_EXPIRED',
  LOGOUT_REQUESTED : 'LOGOUT_REQUESTED',
  PASSWORD_RESET_REQUESTED : 'PASSWORD_RESET_REQUESTED',
  RECIPIENTS_REQUESTED : 'RECIPIENTS_REQUESTED',
  RECIPIENTS_RESOLVED : 'RECIPIENTS_RESOLVED',
  RECIPIENTS_REJECTED : 'RECIPIENTS_REJECTED',
  RECIPIENTS_NEXT_PAGE_REQUESTED : 'RECIPIENTS_NEXT_PAGE_REQUESTED',
  RECIPIENTS_NEXT_PAGE_RESOLVED : 'RECIPIENTS_NEXT_PAGE_RESOLVED',
  RECIPIENTS_NEXT_PAGE_REJECTED : 'RECIPIENTS_NEXT_PAGE_REJECTED',
  RECIPIENT_ADD_REQUEST : 'RECIPIENT_ADD_REQUEST',
  RECIPIENT_ADD_RESOLVED : 'RECIPIENT_ADD_RESOLVED',
  RECIPIENT_ADD_REJECTED : 'RECIPIENT_ADD_REJECTED',
  RECIPIENT_REMOVE_REQUEST : 'RECIPIENT_REMOVE_REQUEST',
  RECIPIENT_REMOVE_RESOLVED : 'RECIPIENT_REMOVE_RESOLVED',
  RECIPIENT_REMOVE_REJECTED : 'RECIPIENT_REMOVE_REJECTED',
  RECIPIENT_TOGGLE_PRIMARY_REQUEST : 'RECIPIENT_TOGGLE_PRIMARY_REQUEST',
  RECIPIENT_TOGGLE_PRIMARY_RESOLVED : 'RECIPIENT_TOGGLE_PRIMARY_RESOLVED',
  RECIPIENT_TOGGLE_PRIMARY_REJECTED : 'RECIPIENT_TOGGLE_PRIMARY_REJECTED',
  CLEAR_EMAIL_RECIPIENTS : 'CLEAR_EMAIL_RECIPIENTS'
};

export const requestLogin = (email, password) => ({
  type: USER_ACTIONS.LOGIN_REQUESTED,
  email,
  password,
});

export const resolveLogin = (email, username, jwtToken) => ({
  type: USER_ACTIONS.LOGIN_RESOLVED,
  email,
  username,
  jwtToken,
});

export const rejectLogin = (errors) => {
  return {
    type: USER_ACTIONS.LOGIN_REJECTED,
    errors,
  };
};

export const tokenExpired = () => {
  return {
    type: USER_ACTIONS.TOKEN_EXPIRED,
  }
}

export const requestLogout = () => ({
  type: USER_ACTIONS.LOGOUT_REQUESTED,
});

export const requestResetPassword = (email)=>{
  return{
    type:USER_ACTIONS.PASSWORD_RESET_REQUESTED,
    email: email
  }
}

export const clearEmailRecipients = ()=>{
  return{
    type: USER_ACTIONS.CLEAR_EMAIL_RECIPIENTS
  }
}

export const requestEmailRecipients = (jwtToken) =>{
  return{
    type: USER_ACTIONS.RECIPIENTS_REQUESTED,
    jwtToken,
  }
}

export const resolveEmailRecipients = (recipients,hasMore)=>{
  return{
    type: USER_ACTIONS.RECIPIENTS_RESOLVED,
    recipients,
    hasMore,
  }
}

export const rejectEmailRecipients = (errors)=>{
  return{
    type: USER_ACTIONS.RECIPIENTS_REJECTED,
    errors,
  }
}

export const requestEmailRecipientsNextPage = (url, jwtToken) =>({
  type: USER_ACTIONS.RECIPIENTS_NEXT_PAGE_REQUESTED,
  url,
  jwtToken
})

export const resolveEmailRecipientsNextPage = (recipients,hasMore)=>({
  type: USER_ACTIONS.RECIPIENTS_NEXT_PAGE_RESOLVED,
  recipients,
  hasMore,
})

export const rejectEmailRecipientsNextPage = (errors)=>({
  type: USER_ACTIONS.RECIPIENTS_NEXT_PAGE_REJECTED,
  errors,
})

export const requestTogglePrimaryRecipient = (id, primary, jwtToken) =>{
  return{
  type: USER_ACTIONS.RECIPIENT_TOGGLE_PRIMARY_REQUEST,
  id,
  primary,
  jwtToken
  }
}

export const resolveTogglePrimaryRecipient = (recipient) =>({
  type: USER_ACTIONS.RECIPIENT_TOGGLE_PRIMARY_RESOLVED,
  recipient,
})

export const rejectTogglePrimaryRecipient = (errors)=>({
  type: USER_ACTIONS.RECIPIENT_TOGGLE_PRIMARY_REJECTED,
  errors,
})

export const requestAddNewRecipient = (email, jwtToken) =>{
  return{
    type: USER_ACTIONS.RECIPIENT_ADD_REQUEST,
    email,
    jwtToken
  }
}

export const resolveAddNewRecipient = (recipient) =>{
  return{
    type: USER_ACTIONS.RECIPIENT_ADD_RESOLVED,
    recipient
  }
}

export const rejectAddNewRecipient = (errors)=>{
  return {
    type: USER_ACTIONS.RECIPIENT_ADD_REJECTED,
    errors
  }
}

export const requestRecipientDelete = (id,jwtToken)=>({
  type: USER_ACTIONS.RECIPIENT_REMOVE_REQUEST,
  id,
  jwtToken,
})

export const resolveRecipientDelete = (id)=>({
  type: USER_ACTIONS.RECIPIENT_REMOVE_RESOLVED,
  id,
})

export const rejectRecipientDelete = (errors) =>({
  type: USER_ACTIONS.RECIPIENT_REMOVE_REJECTED,
  errors,
})
