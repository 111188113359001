import React, {useEffect, useState} from 'react';
import {sensorService} from '../../../services';
import {useSelector} from "react-redux";
import {jwtTokenSelector} from '../../../selectors/userSelectors';
import Gauge from './Gauge';
import Grid from "@material-ui/core/Grid";
import BooleanGauge from "./BooleanGauge";
import Constants from "../../../common/constants";
import DiscomfortIndexGauge from "./DiscomfortIndexGauge";
import {useTranslation} from 'react-i18next';
import { useInterval } from '../../../common/intervalTimer';
import { isRefreshEnabled, refreshRate } from '../../../selectors/settingsSelectors';
import find from 'lodash/find';

const renderGauge = (sensorType, gauge_info, i) => {
  let height = 400;
  let columns = 12;
  // let height = sensorType === Constants.DISCOMFORT_INDEX_SENSOR_TYPE ? 200 : 400;
  // let columns = sensorType === Constants.DISCOMFORT_INDEX_SENSOR_TYPE ? 6 : 12;
  //
  // if (gauge_info.value_type === Constants.DISCOMFORT_INDEX_VALUE_TYPE)
  //   return <DIGauge key={i} index={i} gauge_info={gauge_info} height={400} columns={12}/>;
  // else if (gauge_info.value_type === Constants.BOOL_VALUE_TYPE)
  //   return <BoolIndicator key={i} index={i} gauge_info={gauge_info} height={400} columns={12}/>;
  // else
  //   return <Gauge key={i} index={i} gauge_info={gauge_info} height={height} columns={columns}/>;
    
  if (gauge_info.data_type.toLowerCase() === 'float' && gauge_info.name === Constants.DISCOMFORT_INDEX_MEASUREMENT_TYPE)
    return <DiscomfortIndexGauge key={i} index={i} gauge_info={gauge_info} height={height} columns={columns}/>;
  if (gauge_info.data_type.toLowerCase() === 'float')
    return <Gauge key={i} index={i} gauge_info={gauge_info} height={height} columns={columns}/>;
  else if (gauge_info.data_type.toLowerCase() === 'boolean')
    return <BooleanGauge key={i} index={i} gauge_info={gauge_info} height={120} columns={columns}/>;

}

export const RenderMultiGauge = ({isLoading = false, hasError = false, gaugeInfos = [], sensorType}) => {
  const {t} = useTranslation();

  const filtered_gauge_infos = Constants.getSensorType(sensorType) !== Constants.DISCOMFORT_INDEX_MEASUREMENT_TYPE ?
  gaugeInfos : [find(gaugeInfos, x => x.name === Constants.DISCOMFORT_INDEX_MEASUREMENT_TYPE)];
  
  if (isLoading)
    return <div>{t("Loading Gauges")}</div>;

  if (hasError)
    return <div>{t("There was an error while retrieving the gauge data")}</div>;

  if (gaugeInfos.length === 0)
    return <p>{t("No recent communication from the sensor")}</p>;

  return <Grid container>
    {filtered_gauge_infos.map((gauge_info, i) => renderGauge(sensorType, gauge_info, i))}
    </Grid>;
}

const MultiGauge = ({sensor_id}) => {
  
  const jwtToken = useSelector(jwtTokenSelector);
  const [sensorType, setSensorType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [gaugeInfos, setGaugeInfos] = useState([]);
  const [hasError, setHasError] = useState(false);

  const delay_in_ms = useSelector(refreshRate);
  const delay = useSelector(isRefreshEnabled) ? delay_in_ms : null;

  const fetchGauges =()=>{
    setIsLoading(true);
    sensorService.getGauges(sensor_id, jwtToken)
    .then(result => {
      setGaugeInfos(result.gauge_infos);
      setSensorType(result.sensor_type);
      setIsLoading(false);
    })
    .catch(err => {
      setIsLoading(false);
      setHasError(true);
    })
  }

  useInterval(()=>{
    fetchGauges();
  },delay);

  useEffect(() => {
    fetchGauges();  
    }, [sensor_id, jwtToken]
  );

  return <RenderMultiGauge isLoading={isLoading} hasError={hasError} gaugeInfos={gaugeInfos} sensorType={sensorType}/>

}

export default MultiGauge;
