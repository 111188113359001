export const dashboardPanelValuesSelector = state => ({
  sensors: state.dashboard.sensors,
  online: state.dashboard.online,
  alarm: state.dashboard.alarm,
  battery_low: state.dashboard.battery_low
});

export const dashboardLastResolvedSelector = state => state.dashboard.lastResolved;

export const dashboardSelector = state => state.dashboard;

export const sideMenuStatusSelector = state => state.dashboard.sideMenuShow;
