const host = process.env.REACT_APP_API_HOST;

const post_init = {
  method: 'POST',
  mode: 'cors',
  cache: 'default',
};

const put_init = {
  method: 'PUT',
  mode: 'cors',
  cache: 'default',
};

const get_init = {
  method: 'GET',
  mode: 'cors',
  cache: 'default'
}

const delete_init ={
  method: 'DELETE',
  mode: 'cors',
  cache: 'default'
}

const makeHeaders = (otherHeaders = null) => {
  const baseHeaders = {
    'Content-type': 'application/json',
    Accept: 'application/json',
  };

  if (otherHeaders === null) {
    return baseHeaders;
  }

  return {
    ...baseHeaders,
    ...otherHeaders,
  };
};

const myFetch = (url, requestOptions, absolute_url = false) => {
  const fetchUrl = absolute_url ? url : `${host}${url}`;
  return fetch(fetchUrl, requestOptions)
    .then(response => {
      switch (response.status) {
        case 200:        
        case 201:
          return response;
        case 401:
          // token expired action
          return response.json().then(err => Promise.reject({
            result: "unauthorized",
            detail: "Token expired please login again"
          }));
        default:
          return response.json().then(err => Promise.reject(err));
      }
    })
    .then(response => response.json())
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error))
}

export const post = (url, jwtToken, body) => {
  const requestOptions = {
    ...post_init,
    body: JSON.stringify(body),
    headers: makeHeaders(jwtToken !== null ? {
      'Authorization': `JWT ${jwtToken}`
    } : null),
  };
  return myFetch(url, requestOptions);
}

export const get = (url, jwtToken) => {
  const requestOptions = {
    ...get_init,
    headers: makeHeaders(jwtToken !== null ? {
      'Authorization': `JWT ${jwtToken}`
    } : null),
  };
  return myFetch(url, requestOptions);
};

export const get_absolute = (url, jwtToken) => {
  const requestOptions = {
    ...get_init,
    headers: makeHeaders(jwtToken !== null ? {
      'Authorization': `JWT ${jwtToken}`
    } : null),
  };
  return myFetch( url, requestOptions, true);
};

export const delete_method = (url,jwtToken) => {
  // TODO: fix this
  const requestOptions = {
    ...delete_init,
    headers: makeHeaders(jwtToken !== null ? {
      'Authorization': `JWT ${jwtToken}`
    } : null),
  };
  const fetchUrl = `${host}${url}`;
  return fetch(fetchUrl,requestOptions)
    .then(response=>response.status)
    .catch(error => Promise.reject({
      result: "unauthorized",
      detail: "Error"
  }));
};

export const put = (url, jwtToken, body) => {
  const requestOptions = {
    ...put_init,
    body: JSON.stringify(body),
    headers: makeHeaders(jwtToken !== null ? {
      'Authorization': `JWT ${jwtToken}`
    } : null),
  };
  return myFetch(url, requestOptions);
};

