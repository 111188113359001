export const SETTINGS_ACTIONS = {
  SET_REFRESH: 'SET_REFRESH',
  SET_ACTIVE: 'SET_ACTIVE',
 }

export const setRefresh =(refresh)=>({
  type: SETTINGS_ACTIONS.SET_REFRESH,
  refresh,
});

export const setActive = (active)=>({
  type: SETTINGS_ACTIONS.SET_ACTIVE,
  active,
});