import React from 'react';
import { IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import 'moment/locale/el';
import 'moment/locale/en-gb';
import 'moment/locale/it';
import 'moment/locale/es';
import 'moment/locale/pt';
import 'moment/locale/nl';
import 'moment/locale/pl';
import 'moment/locale/ru';
import 'moment/locale/bg';
import 'moment/locale/sr';
import 'moment/locale/ja';
import 'moment/locale/ar';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/tr';
import 'moment/locale/es';
import moment from 'moment';
import ReactCountryFlag from "react-country-flag";

const FlagIcon = ({ locale }) => {
  return <ReactCountryFlag
    styleProps={{
      width: '20px',
      height: '20px'
    }}
    code={locale}
    svg
  />
}

export const LanguageSelector = ({showText=false}) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [language, setLanguage] = React.useState('en');

  const { t,i18n } = useTranslation();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectLanguage = (language) => {
    setLanguage(language);
    i18n.changeLanguage(language);
    moment.locale(language);
    setAnchorEl(null);
  }

  return <div>
    {/* {language} */}
    
    <IconButton
      color="inherit"
      onClick={handleClick}
      
      >
      <FontAwesomeIcon icon={["far", "language"]} />
      <Typography style={{fontSize:15}}>
        {showText && t('Select Language')}
      </Typography>
    </IconButton>
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      getContentAnchorEl={null}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
    >
      <MenuItem onClick={() => { handleSelectLanguage('en') }} button>
        <div>
          <FlagIcon locale={"gb"} />
          English
        </div>
      </MenuItem>
      <MenuItem onClick={() => { handleSelectLanguage('el') }} button>
        <div>
          <FlagIcon locale={"gr"} />
          Greek
        </div>
      </MenuItem>
	  <MenuItem onClick={() => { handleSelectLanguage('es') }} button>
        <div>
          <FlagIcon locale={"es"} />
          Spanish
        </div>
      </MenuItem>
      <MenuItem onClick={() => { handleSelectLanguage('it') }} button>
        <div>
          <FlagIcon locale={"it"} />
          Italian
        </div>
      </MenuItem>
      <MenuItem onClick={() => { handleSelectLanguage('ru') }} button>
        <div>
          <FlagIcon locale={"ru"} />
          Russian
        </div>
      </MenuItem>
      <MenuItem onClick={() => { handleSelectLanguage('de') }} button>
        <div>
          <FlagIcon locale={"de"} />
          German
        </div>
      </MenuItem>
      <MenuItem onClick={() => { handleSelectLanguage('tr') }} button>
        <div>
          <FlagIcon locale={"tr"} />
          Turkish
        </div>
      </MenuItem>
    </Menu>
  </div>
}