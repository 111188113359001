import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from "@material-ui/core/CircularProgress";
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom'
import { requestLogin } from '../actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { isUserLoading, userErrors } from "../selectors/userSelectors";
import logo from "./logo.png";
import {useTranslation} from 'react-i18next';
import { LanguageSelector } from '../layout/LanguageSelector';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  mt1: {
    marginTop: theme.spacing(1),
  },
  ml1: {
    marginLeft: theme.spacing(1),
  },
  loading: {
    margin: "auto",
  },
  flex: {
    display: 'flex'
  },
  logo: {
    width: "50%",
    margin: theme.spacing(3)
  }
});

function SignIn(props) {
  const { classes } = props;
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const isLoading = useSelector(isUserLoading);
  const errors = useSelector(userErrors);
  const {t} = useTranslation();

  const renderErrors = errors => {
    return (
      errors.map((error, index) => <Typography key={index} color="error">{error}</Typography>)
    );
  };

  const handleSubmit = (event)=>{
    event.preventDefault();
    dispatch(requestLogin(email, password));
  }

  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <img className={classes.logo} src={logo} alt="S2W logo" />
        <Typography component="h1" variant="h5">
          {t('Sign in')}
        </Typography>
        {errors && errors.details != null && errors.details.non_field_errors != null && renderErrors(errors.details.non_field_errors)}
        <form className={classes.form}>
          <FormControl error={errors && errors.details && errors.details.email && true} margin="normal" required
            fullWidth>
            <InputLabel htmlFor="email">{t('Email Address')}</InputLabel>
            <Input id="email" name="email" autoComplete="email" autoFocus value={email}
              onChange={(evt) => setEmail(evt.target.value)} />
            {errors && errors.details && errors.details.email && renderErrors(errors.details.email)}
          </FormControl>
          <FormControl error={errors && errors.details && errors.details.password && true} margin="normal" required
            fullWidth>
            <InputLabel htmlFor="password">{t('Password')}</InputLabel>
            <Input name="password" type="password" id="password" autoComplete="current-password" value={password}
              onChange={(evt) => setPassword(evt.target.value)} />
            {errors && errors.details && errors.details.password && renderErrors(errors.details.password)}
          </FormControl>
          <div className={classes.flex}>
            {isLoading
              ? <CircularProgress className={classes.loading} />
              : <Button
                type="submit"
                onClick={handleSubmit}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {t('Sign in')}
              </Button>
            }
          </div>
        </form>
        <Typography variant="subtitle2" className={classes.mt1}>
          {t('Forgot Password')}?
          <Link className={classes.ml1} component={RouterLink} to="/passwordreset">
            {t('We can help')}
          </Link>
        </Typography>
        <Typography variant="subtitle2">
          {t('No account yet')}?
          <Link className={classes.ml1} component={RouterLink} to="/register">
            {t('Register')}
          </Link>
        </Typography>
        <LanguageSelector showText={true}/>
      </Paper>
    </main>
  );
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);
