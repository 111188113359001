import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import {useInterval} from '../../common/intervalTimer';

import SensorTable from './sensortable.jsx';
import SensorFilters from './sensorfilters.jsx';
import {requestSensorList, setSearchFilter} from '../../actions';
import {areSensorsLoadingSelector, sensorsSearchPattern, lastListUpdateSelector, isMuteLoadingSelector} from "../../selectors/sensorSelectors";
import {isRefreshEnabled, refreshRate} from "../../selectors/settingsSelectors";
import {Paper, Typography} from '@material-ui/core';
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  paper: {
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(2),
  },
  progress: {
    margin: theme.spacing(2),
  },
  loading: {
    width: '100%',
    height: '738px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    marginBottom: theme.spacing(1),
    alignSelf: 'flex-end',
    display: 'flex',
  },
  refresh: {
    margin: theme.spacing(1, 0, 0.5, 1),
    minWidth: theme.spacing(6),
  }
}));

const SensorList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { t} = useTranslation();

  const muteIsLoading = useSelector(isMuteLoadingSelector);
  const isLoading = useSelector(areSensorsLoadingSelector) ;
  const searchPattern = useSelector(sensorsSearchPattern);
  const lastListUpdate = useSelector(lastListUpdateSelector);
  const shouldFetch = lastListUpdate === null || moment().subtract(15, 'minutes').isAfter(lastListUpdate);
  const onSearchChanged = (pattern) => dispatch(setSearchFilter(pattern));
  const delay_in_ms = useSelector(refreshRate);
  const delay = useSelector(isRefreshEnabled) ? delay_in_ms : null;

  function loadData(){
    dispatch(requestSensorList(true));
  }
  
  useEffect(() => {
    if (shouldFetch){
      dispatch(requestSensorList());
    }
  }, [dispatch,shouldFetch]);

  useInterval(()=>{
    loadData();
  },delay);

  return (
    <Grid container spacing={3}>
      <Grid item md={9} xs={12}>
        < Paper className={classes.paper}>
          <div className={classes.table}>
              <div className={classes.header}>
                <TextField
                  id="standard-name"
                  label= {t("Search")}
                  className={classes.textField}
                  value={searchPattern}
                  placeholder={t('Search')}
                  onChange={(e) => onSearchChanged(e.target.value)}
                  margin="dense"
                  variant="outlined"
                />
                <Button
                  variant="outlined" className={classes.refresh}
                  onClick={() => dispatch(requestSensorList())}
                >
                  <FontAwesomeIcon icon={["far", "sync"]}/>
                </Button>
              </div>
          {isLoading && !muteIsLoading
            ? <div className={classes.loading}>
              <CircularProgress className={classes.progress}/>
            </div>
            : <SensorTable/>
          }
          </div>
        </Paper>
      </Grid>
      <Grid item md={3} xs={12}>
        <Paper className={classes.paper}>
          <Typography variant="h5" component="h3">
            {t('Quick Filters')}
          </Typography>
          <div className="panel-body">
            <SensorFilters/>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default SensorList;
