import React from 'react';
import { FormControlLabel, Switch, Paper, Typography, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector} from "react-redux";
import { isRefreshEnabled } from '../../selectors/settingsSelectors';
import { setRefresh } from '../../actions';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme=>({
  paper:{
    padding: theme.spacing(2)
  }
}))
const RefreshSetting = ()=>{
  const classes = useStyles();
  const dispatch = useDispatch();
  const refreshIsEnabled = useSelector(isRefreshEnabled);
  const {t} = useTranslation();

  const changeEnabled =()=>{
    dispatch(setRefresh(!refreshIsEnabled));
  }

  return<>
  <Paper className={classes.paper}>
    <Typography variant="h5" gutterBottom>{t("General settings")}</Typography>
    <FormControlLabel control=
      {<Switch checked={refreshIsEnabled} onChange={changeEnabled} value=""/>}
      label={t("Auto-Refresh")}
    />
  </Paper>
  </>
}

export default RefreshSetting;