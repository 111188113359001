import React, { useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
import { useDispatch} from 'react-redux';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { requestResetPassword } from '../actions/userActions';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import logo from "./logo.png";
import { useTranslation } from 'react-i18next';


const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  mt1: {
    marginTop: theme.spacing(1),
  },
  ml1: {
    marginLeft: theme.spacing(1),
  },
  loading: {
    margin: "auto",
  },
  flex: {
    display: 'flex'
  },
  logo: {
    width: "50%",
    margin: theme.spacing(3)
  }
});

const PasswordResetPage = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const { t} = useTranslation();

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(requestResetPassword(email));
  }

  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <img className={classes.logo} src={logo} alt="S2W logo" />
        <Typography component="h1" variant="h5">
          {t('Password reset')}
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <FormControl margin="normal" required
            fullWidth>
            <InputLabel htmlFor="email">{t('Email Address')}</InputLabel>
            <Input id="email" name="email" autoComplete="email" autoFocus value={email}
              onChange={(evt) => setEmail(evt.target.value)} />
          </FormControl>
          <div className={classes.flex}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}>
              {t('Password reset')}
              </Button>
          </div>
        </form>
        <Typography variant="subtitle2" className={classes.mt1}>
          {t('Already have an account')}?
          <Link className={classes.ml1} component={RouterLink} to="/login">
            {t('Login')}
          </Link>
        </Typography>
        <Typography variant="subtitle2">
          {t('No account yet')}?
          <Link className={classes.ml1} component={RouterLink} to="/register">
            {t('Register')}
          </Link>
        </Typography>
      </Paper>
    </main>
  )
}

export default withStyles(styles)(PasswordResetPage);