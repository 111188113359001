import {get} from './baseRequestService';
import moment from "moment";

export default {
  getEvents: (sensorRef, startDate, endDate, jwtToken, page=1) => {
    startDate = encodeURIComponent(moment(startDate).format());
    endDate = encodeURIComponent(moment(endDate).format());
    return get(`/v1/sensors/${sensorRef}/events/?fromdatetime=${startDate}&todatetime=${endDate}&page=${page}`, jwtToken);
  },
};
