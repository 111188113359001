import find from 'lodash/find';

export const areSensorsLoadingSelector = state => state.sensors.isLoading;

export const isMuteLoadingSelector = state =>state.sensors.muteIsLoading;

export const sensorsSearchPattern = state => state.sensors.searchPattern;

export const singleSensor = id => state => find(state.sensors.sensors, x => x.ref_id === id);

export const selectedSensor = state => state.sensors.selectedSensor;

export const sensorError = state => state.sensors.error;

export const selectedSensorExists = state => state.sensors.selectedSensorExists;

export const selectedSensorLoading = state => state.sensors.selectedSensorLoading;

export const gaugeSelector = state => state.sensors.selectedSensorGauges;

export const lastListUpdateSelector = state => state.sensors.lastListUpdate;

export const lastSensorUpdate = state => state.sensors.lastSensorUpdate;