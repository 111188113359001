import React, {useEffect} from 'react';
import { List, ListItem, ListItemText, ListItemSecondaryAction, makeStyles, CircularProgress } from '@material-ui/core';
import { emailRecipientsSelector, emailRecipientsLoadingSelector, jwtTokenSelector } from '../../../selectors/userSelectors';
import { useSelector, useDispatch } from 'react-redux';
import { requestEmailRecipients } from '../../../actions';

import RecipientsActions from './RecipientsActions';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root:{
    overflow: 'auto',
    maxHeight: '300px',
  },
  recipientItemGray:{
    backgroundColor: "#DCDCDC"
  },
  recipientItemWhite:{
    backgroundColor: "#ffffff"
  },
  loading: {
    margin: "auto",
  }
}));

const RecipientList =()=>{
  const classes = useStyles();
  const dispatch = useDispatch();
  const recipients = useSelector(emailRecipientsSelector);  
  const jwtToken = useSelector(jwtTokenSelector);
  const {t} = useTranslation();
  const recipientsLoading = useSelector(emailRecipientsLoadingSelector);
  
  const recipientsExist = recipients === undefined ? false : recipients.length > 0 ? true : false;
  
  useEffect(()=>{
    if(!recipientsExist)
      dispatch(requestEmailRecipients(jwtToken))
    return ()=>{
      
    }
  },[jwtToken,dispatch,recipientsExist])
  
  if(recipientsLoading)
    return <CircularProgress className={classes.loading}/>

  if(!recipientsExist)
    return <div>{t("Recipients do not exist")}</div>

  return<>
  <List dense className={classes.root}>
    {recipients.map((value,idx)=>{
      return(
        <ListItem key={value.email} button className={ idx % 2 === 0 ? classes.recipientItemGray:classes.recipientItemWhite}>
          <ListItemText id={value.id} primary={`${value.email}`}/>
          <ListItemSecondaryAction>
            <RecipientsActions recipient={value} />
          </ListItemSecondaryAction>
        </ListItem>
      )
    })}
  </List>
  </>


}

export default RecipientList;