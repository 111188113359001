import React from 'react';
import { connect } from 'react-redux';

import * as actions from '../../actions';
import FilterLink from './filterLink.jsx';

const mapDispatchToProps = dispatch => {
  return {
    onFilterClick: name => {
      dispatch(actions.setBatteryFilter(name));
    },
  };
};

const mapStateToProps = state => ({
  currentFilter: state.sensors.batteryFilter,
});

const BattteryFilterLink = connect(mapStateToProps, mapDispatchToProps)(FilterLink);

const BatteryFilters = () => {  
  return <div id="connectivity-filters" className="list-group">
    <BattteryFilterLink filterName={actions.BATTERY_FILTERS.ALL} />
    <BattteryFilterLink filterName={actions.BATTERY_FILTERS.BATTERY_OK} />
    <BattteryFilterLink filterName={actions.BATTERY_FILTERS.BATTERY_LOW} />    
  </div>;
};

export default BatteryFilters;