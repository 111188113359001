import React, { useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import Chart from "./Chart";
import { Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { clearMeasurements, clearEvents } from "../../../actions";
import ChartConfigurator from "./ChartConfigurator";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { measurementsSelector, measurementsLoadingSelector, measurementsErrorsSelector } from "../../../selectors/measurementsSelectors";
import {filter, keys} from 'lodash';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "400px",
  },
  chartContainer: {
    display: "flex",
  },
  loading: {
    margin: "auto",
  },
  configurator: {
    padding: theme.spacing(2)
  }
}));

const ChartPanel = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(measurementsLoadingSelector);
  const classes = useStyles();
  const {t} = useTranslation();
  const measurements = useSelector(measurementsSelector);
  const emptyMeasurements = measurements == null || measurements.length === 0;
  const error = useSelector(measurementsErrorsSelector);

  const measurement_names = emptyMeasurements ? [] : filter(keys(measurements[0]), x => x !== 'time');

  useEffect(() => {
    return () => {
      
      if (!isLoading && !emptyMeasurements) {
        dispatch(clearEvents());
        dispatch(clearMeasurements());
      }
    }
  },[measurements,dispatch,emptyMeasurements,isLoading]);

  const render_chart_panel = () => {
    if (isLoading)
      return <CircularProgress className={classes.loading} />;
    else if (!isLoading && emptyMeasurements && error)
      return <Typography className={classes.loading}>{t("Error while loading measurements")}</Typography>;
    else if (!isLoading && emptyMeasurements)
      return <Typography className={classes.loading}>{t("No measurements loaded")}</Typography>
    else if (!isLoading && !emptyMeasurements)      
      return <Chart measurements={measurements} measurement_names={measurement_names} />
  }

  return <Grid container className={classes.root}>
    <Grid item xs={12} md={10} className={classes.chartContainer}>
      {render_chart_panel()}
    </Grid>
    <Grid item xs={12} md={2} className={classes.configurator} >
      <ChartConfigurator include_events={true} show_switches={true} />
    </Grid>
  </Grid>
}

export default ChartPanel;
