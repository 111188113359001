import { all } from 'redux-saga/effects';
import loginUserSaga from './userSagas';
import sensorListSaga from './sensorListSagas';
import measurementsSaga from "./measurementsSagas";
import dashboardSaga from './dashboardSagas';
import eventsSaga from './eventsSagas';
import sensorSaga from './sensorsSagas';
export default function* rootSaga() {
  yield all([
    loginUserSaga(),
    sensorListSaga(),
    measurementsSaga(),
    dashboardSaga(),
    eventsSaga(),
    sensorSaga(),
  ]);
}
