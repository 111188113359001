import React, {useState}  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { jwtTokenSelector } from '../../selectors/userSelectors';
import { useTranslation } from 'react-i18next';
import { sensorService } from '../../services';
import { Formik, Field, Form } from 'formik';
import { Switch} from 'formik-material-ui';
import * as yup from 'yup';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import NotifyOnlineOfflineHeader from './NotifyOnlineOfflineHeader';
import { makeStyles, FormControlLabel, SnackbarContent } from '@material-ui/core';
import { amber, green } from "@material-ui/core/colors";
import FieldErrorRenderer from './FieldErrorRenderer';
import { updateSensorNotifications } from '../../actions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  fieldError: {
    color: theme.palette.error.main
  },
  formControl: {
    minWidth: 200
  },
  paper: {
    marginBottom: theme.spacing(2),
  }
}));

const schema = yup.object().shape({
  notify_offline: yup.boolean(),
  notify_online: yup.boolean(),
});

const SensorOfflineOnline = ({sensorRef, online, offline }) =>{
  const classes = useStyles();
  const jwtToken = useSelector(jwtTokenSelector);
  const dispatch = useDispatch();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitErrors, setSubmitErrors] = useState(null);
  const {t} = useTranslation();

  const submitConfig = (notifyOptions, actions) => {
    sensorService.updateNotifySettings( {"notify_when_offline": notifyOptions.offline,"notify_when_online": notifyOptions.online}, sensorRef, jwtToken)
      .then(() => {
        setSubmitSuccess(true);
        setSubmitErrors(null);
        actions.resetForm(notifyOptions);
        dispatch(updateSensorNotifications(sensorRef,notifyOptions));
      })
      .catch((err) => {
        setSubmitSuccess(false);
        setSubmitErrors(err);
        actions.setSubmitting(false);
      }
      )
  };
  return <Formik
    initialValues = {{offline,online}}
    onSubmit = {submitConfig}
    validationSchema = {schema}
  >
  {({
    errors,
    touched,
    dirty,
    handleSubmit,
    isSubmitting,
    handleReset
  }) => (
    
    <Paper className={classes.paper}>
      <NotifyOnlineOfflineHeader
        errors={errors} handleReset={handleReset} handleSubmit={handleSubmit} isSubmitting={isSubmitting}
        title={t("Connectivity notifications configuration")} touched={touched} dirty={dirty}
      />
      <Form>
        <Grid container spacing={3} className={classes.root}>
          <Grid item xs={12}>
            <Field
              type='text'
              label={t('Notify when offline')}
              name='offline'
              render={({field, form}) => {
                return <FormControlLabel control={<Switch form={form} field={field}/>} label={t('Notify when offline')}/>
              }}
              fullWidth
            />
            {submitErrors &&
            <FieldErrorRenderer field='notify_offline' details={submitErrors.details} className={classes.fieldError}/>}
          </Grid>
          <Grid item xs={12}>
          <Field
              type='text'
              label={t('Notify when online')}
              name='online'
              render={({field, form}) => {
                return <FormControlLabel control={<Switch form={form} field={field}/>} label={t('Notify when online')}/>
              }}
              fullWidth
            />
            {submitErrors &&
            <FieldErrorRenderer field='notify_online' details={submitErrors.details} className={classes.fieldError}/>}
          </Grid>
        </Grid>
      </Form>
      {submitSuccess &&
      <SnackbarContent className={classes.success} message={
        <span id="client-snackbar" className={classes.message}>{t('Configuration saved successfully')}</span>
      }
      />}
      {submitErrors && submitErrors.detail &&
      <SnackbarContent className={classes.error} message={
        <span>{submitErrors.detail && submitErrors.detail}</span>
      }
      />}
    </Paper>
  )}
  </Formik>
}

export default SensorOfflineOnline;