
export const SENSOR_ACTIONS ={
  SENSOR_REQUEST: 'SENSOR_REQUEST',
  SENSOR_REFRESH : 'SENSOR_REFRESH',
  SENSOR_REJECTED: 'SENSOR_REJECTED',
  SENSOR_RESOLVE : 'SENSOR_RESOLVE'
};

export const requestSensor = (sensorRef) => ({
    type: SENSOR_ACTIONS.SENSOR_REQUEST,
    sensorRef,
});

export const refreshSensor = (ref_id) => {
  return{ 
  type: SENSOR_ACTIONS.SENSOR_REFRESH,
  sensorRef:ref_id,
 };
};

export const resolveSensor = (sensor) => ({
  type: SENSOR_ACTIONS.SENSOR_RESOLVE,
  sensor,
});

export const rejectSensor = (error)=>{
  return{
    type : SENSOR_ACTIONS.SENSOR_REJECTED,
    error
  }
}