import React from 'react';
import { connect } from 'react-redux';

import * as actions from '../../actions';
import FilterLink from './filterLink.jsx';

const mapDispatchToProps = dispatch => {
  return {
    onFilterClick: name => {
      dispatch(actions.setAlarmFilter(name));
    },
  };
};

const mapStateToProps = state => ({
  currentFilter: state.sensors.alarmFilter,
});

const AlarmFilterLink = connect(mapStateToProps, mapDispatchToProps)(FilterLink);

const AlarmFilters = () => {
  return <div id="connectivity-filters" className="list-group">
    <AlarmFilterLink filterName={actions.ALARM_FILTERS.ALL} />
    <AlarmFilterLink filterName={actions.ALARM_FILTERS.HAS_ALARM} />
    <AlarmFilterLink filterName={actions.ALARM_FILTERS.NO_ALARM} />
  </div>;
};

export default AlarmFilters;