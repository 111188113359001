import React from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withStyles } from "@material-ui/core";
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { useDispatch } from "react-redux";
import { setSensorSelected } from "../../actions";
import { useTranslation } from 'react-i18next';

const styles = theme => ({
  margin: {
    minWidth: theme.spacing(1)
  },
})

const AdapterLink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

const EditButtonIcon = ({ sensor_id, classes, display_title = false }) => {
  const dispatch = useDispatch();
  const onSensorSelected = () => dispatch(setSensorSelected(sensor_id));

  const {t} = useTranslation();

  return <Tooltip title={t("Edit")} placement="top">
    <Button
      variant="contained" className={classes.margin} color="primary" component={AdapterLink}
      to={`/sensors/${sensor_id}/edit`} size="small"
      onClick={() => onSensorSelected()}
    >

      <FontAwesomeIcon icon={["far", "edit"]} />
      {display_title && <div>&nbsp;{t("Edit")}</div>}

    </Button>
  </Tooltip >
}


export default withStyles(styles)(EditButtonIcon);

