import React from 'react';
import {useTranslation} from 'react-i18next';
import {Typography} from '@material-ui/core';

export const NotFound = ({text="Not found"}) =>{
  const {t} = useTranslation();
  return (
  <Typography variant="h4" gutterBottom component="h2">
    {t(text)}
  </Typography>
  )
}