import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const disconnected = {
  color: 'red',
};

const OnlineIcon = ({isOnline}) =>
  isOnline
  ? <FontAwesomeIcon icon={["far", "cloud"]}/>
  : <span className="fa-stack" style={disconnected}>
  <FontAwesomeIcon className="fa-stack-2x" icon={["far", "cloud"]}/>
  <FontAwesomeIcon className="fa-stack-2x" icon={["far", "slash"]}/>
  </span>

OnlineIcon.defaultProps = {
  isOnline: false,
};

OnlineIcon.propTypes = {
  isOnline: PropTypes.bool.isRequired,
};

export default OnlineIcon;
