import { USER_ACTIONS } from '../actions/userActions';
import _ from 'lodash'
const initialUserState = {
  isAuthenticated: false,
  username: null,
  email: null,
  token: null,
  isLoading: false,
  recipientAddIsLoading: false,
  errors: null,
  emailRecipients: [],
  emailRecipientsLoading: false,
};

const userReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case USER_ACTIONS.LOGIN_REQUESTED:
      return {
        ...state,
        isLoading: true,
        errors: null,
      };
    case USER_ACTIONS.LOGIN_RESOLVED:
      return {
        ...state,
        username: action.username,
        email: action.email,
        token: action.jwtToken,
        isAuthenticated: true,
        isLoading: false,
        errors: null,
      };
    case USER_ACTIONS.LOGIN_REJECTED:
      return {
        ...initialUserState,
        errors: action.errors,
      };
    case USER_ACTIONS.TOKEN_EXPIRED:
      return {
        ...initialUserState,
        errors: {
          details: {
            non_field_errors: ["Session has expired please login again"]
          }
        }
      }
    case USER_ACTIONS.LOGOUT_REQUESTED:
      return {
        ...initialUserState
      }
    case USER_ACTIONS.RECIPIENTS_REQUESTED:
      return{
        ...state,
        emailRecipientsLoading: true,
      }
    case USER_ACTIONS.RECIPIENTS_RESOLVED:
      return{
        ...state,
        emailRecipients: [...action.recipients],
        emailRecipientsLoading: action.hasMore,
        errors: null,
      }
    case USER_ACTIONS.RECIPIENTS_NEXT_PAGE_RESOLVED:
      return{
        ...state,
        emailRecipients: [ ...action.recipients , ...state.emailRecipients],
        errors: null,
        emailRecipientsLoading: action.hasMore,
      }
    case USER_ACTIONS.RECIPIENTS_REJECTED:
      return {
        ...state,
        emailRecipientsLoading:false,
        errors:{
          details:{
            non_field_errors: action.errors
          }
        }
      }
    case USER_ACTIONS.RECIPIENT_ADD_REQUEST:{
      return{
        ...state,
        recipientAddIsLoading: true,
      }
    }
    case USER_ACTIONS.RECIPIENT_ADD_RESOLVED:{
      return{
        ...state,
        emailRecipients: [...state.emailRecipients, action.recipient],
        recipientAddIsLoading: false,
      }
    }
    case USER_ACTIONS.RECIPIENT_ADD_REJECTED:{
      return {
        ...state,
        recipientAddIsLoading: false,
        errors: action.errors,
        // display error
        // add user not loading
      }
    }
    case USER_ACTIONS.RECIPIENT_TOGGLE_PRIMARY_RESOLVED:{
      let temp_recipients = state.emailRecipients;
      temp_recipients = temp_recipients.map((rec)=>{
        return {
          ...rec,
          primary: rec.id === action.recipient.id ? true : false
        }
      })

      return{
        ...state,
        emailRecipients: temp_recipients,
      }
    }
    case USER_ACTIONS.RECIPIENT_TOGGLE_PRIMARY_REJECTED:{
      return{
        ...state,
        errors: action.errors,
      }
    }
    case USER_ACTIONS.RECIPIENT_REMOVE_RESOLVED:{
      return{
        ...state,
        emailRecipients : _.filter(state.emailRecipients,(rec)=>rec.id !== action.id),
      }
    }
    case USER_ACTIONS.RECIPIENT_REMOVE_REJECTED:{
      return{
        ...state,
        errors: action.errors,
      }
    }
    case USER_ACTIONS.CLEAR_EMAIL_RECIPIENTS:{
      return {
        ...state,
        emailRecipients:[]
      }
    }
    default:
      return state;
  }
};

export default userReducer;