import React,{useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import {useTranslation} from 'react-i18next';
import ChangePassword from '../components/UserSettings/ChangePassword';
import RefreshSetting from '../components/UserSettings/RefreshSetting';
import {useDispatch, useSelector} from 'react-redux';
import { clearSelectedSensor } from '../actions';
import { selectedSensor } from '../selectors/sensorSelectors';
// import PlanPricing from '../components/UserSettings/PlanPricing';
import MailRecipients from '../components/UserSettings/RecipientsList/MailRecipients';

const useStyles = makeStyles(theme => ({
  root:{
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2)
  }
}));

const SettingsPage = ()=>{
  const classes = useStyles();
  const {t} = useTranslation();

  const dispatch = useDispatch();
  const selectedS = useSelector(selectedSensor);

  useEffect(()=>{
    if(selectedS !== null){
      dispatch(clearSelectedSensor());
    }

  },[selectedS,dispatch]);
  
  return (
    <>
    <Typography variant="h4" gutterBottom component="h2">
      {t("Settings")}
    </Typography>
    <Grid  className={classes.root} container spacing={3}>
      <Grid item xs={12} md={6} container direction="column">
        <ChangePassword/>
      </Grid>
      <Grid item xs={12} md={6} container direction="column">
        {/* <PlanPricing/> */}
        <MailRecipients/>
        <RefreshSetting/>
      </Grid>
    </Grid>
    </>
  )
}

export default SettingsPage;