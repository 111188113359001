import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./DiscomfortIcon.css"
import { Tooltip } from '@material-ui/core';
import {useTranslation} from 'react-i18next';
const DiscomfortIcon = ({value,small=false}) => {
  
  const {t} = useTranslation();

  const getDIIcon = (value)=>{

    const icon_size = small? "1x" : "6x";

    if(value <-1){
      // very cold
      return <FontAwesomeIcon className={"icon-frigid"} icon={["far", "temperature-frigid"]} size={icon_size}/>
    }
    else if(value <13){
      // cold
      return <FontAwesomeIcon className={"icon-temp-down"} icon={["far", "temperature-down"]} size={icon_size}/>
    }else if(value <15){
      // cool
      return <FontAwesomeIcon className={"icon-cool"} icon={["far", "temperature-down"]} size={icon_size}/>
    }
    else if(value <27 ){
      // ok
      return <FontAwesomeIcon className={"icon-ok"} icon={["far", "thumbs-up"]} size={icon_size}/>
    }
    if (value < 33){
      // caution
      return <FontAwesomeIcon className={"icon-caution"} icon={["far", "exclamation-triangle"]} size={icon_size}/>
    }
    else if (value < 40){
      // extreme caution
      return <FontAwesomeIcon className={"icon-extreme-caution"} icon={["far", "exclamation-triangle"]} size={icon_size}/>
    }
    else if(value < 51){
      // danger
      return <FontAwesomeIcon className={"icon-danger"} icon={["far", "exclamation-triangle"]} size={icon_size}/>
    }
    else if (value >= 51){
      // extreme danger
      return <FontAwesomeIcon className={"icon-extreme-danger"} icon={["far", "exclamation-triangle"]} size={icon_size}/>
    }
    return <FontAwesomeIcon icon={["far", "question"]} size={icon_size}/>
  }

  
  return <Tooltip title={`${t('Discomfort index')}: ${value}`} placement="top">
    <span className="tooltip-primary">
      {getDIIcon(value)}
    </span>
  </Tooltip>
  
}

export default DiscomfortIcon;