import React from 'react'
import VerifiedIcon from './VerifiedIcon';
import TogglePrimary from './TogglePrimary';
import RemoveRecipient from './RemoveRecipient';

const RecipientActions = ({recipient})=>{
  return <>
  <VerifiedIcon verified={recipient.verified}/>
  <TogglePrimary primary={recipient.primary} id={recipient.id} verified = {recipient.verified} />
  <RemoveRecipient recipientID={recipient.id} emailAddress ={recipient.email}/>
  </>
}

export default RecipientActions;