import React from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {withStyles} from "@material-ui/core";

const styles = theme => ({
  error: {
    color: theme.palette.error.main,
  },
})

const SensorFaultIcon = ({current_status, classes}) => {
  if (current_status === 1)
    return <Tooltip title="Sensor at fault" placement="top">
              <span className="tooltip-primary">
                <FontAwesomeIcon className={classes.error} icon={["far", "exclamation-triangle"]}/>
              </span>
    </Tooltip>

  return null;
}

export default withStyles(styles)(SensorFaultIcon);
