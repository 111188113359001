import React, { Component, Suspense } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'

import './App.css';

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import Routes from './routes';

import { blue, indigo } from '@material-ui/core/colors';
import createStore from "./storeConfig";
import './faLibrary';

const { store, persistor } = createStore();

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: blue[900]
    },
    primary: {
      main: indigo[700]
    }
  },
  typography: {
    useNextVariants: true,
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '"Lato"',
      'sans-serif'
    ].join(',')
  }
});

class App extends Component {
  render() {
    return (
      <Suspense fallback="loading">
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <MuiThemeProvider theme={theme}>
              <Routes />
            </MuiThemeProvider>
          </PersistGate>
        </Provider>
      </Suspense>
    );
  }
}

export default App;
