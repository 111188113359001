export const DASHBOARD_ACTIONS = {
  DASHBOARD_REQUESTED: 'DASHBOARD_REQUESTED',
  DASHBOARD_RESOLVED: 'DASHBOARD_RESOLVED',
  DASHBOARD_REJECTED: 'DASHBOARD_REJECTED',
  DASHBOARD_CLEAR: 'DASHBOARD_CLEAR',
  SIDE_MENU_TOGGLE: 'SIDE_MENU_TOGGLE',
};

export const requestDashboard = () => ({
  type: DASHBOARD_ACTIONS.DASHBOARD_REQUESTED,
});

export const resolveDashboard = ({ sensors, online, alarm, battery_low }) => ({
  type: DASHBOARD_ACTIONS.DASHBOARD_RESOLVED,
  sensors,
  online,
  alarm,
  battery_low
});

export const rejectDashboard = (error) => {
  return {
    type: DASHBOARD_ACTIONS.DASHBOARD_REJECTED,
    error,
  };
};

export const clearDashboard = () => {
  return {
    type: DASHBOARD_ACTIONS.DASHBOARD_CLEAR,
  }
}

export const sideMenuToggle = () => {
  return {
    type: DASHBOARD_ACTIONS.SIDE_MENU_TOGGLE
  }
}