import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import SideBar from '../layout/SideBar';
import Header from "../layout/Header";
import { Route } from "react-router";
import Dashboard from '../pages/DashboardPage';
import SensorListPage from '../pages/SensorListPage';
import SensorDetailPage from '../pages/SensorDetailPage';
import SensorEditPage from "../pages/SensorEditPage";
import TopBreadcrumbs from "./TopBreadcrumps";
import SensorMeasurementsPage from '../pages/SensorMeasurementsPage';
import SensorEventsPage from '../pages/SensorEventsPage'
import SettingsPage from '../pages/SettingsPage';
import { useDispatch, useSelector } from 'react-redux';
import { sideMenuStatusSelector } from '../selectors/dashboardSelectors';
import { sideMenuToggle } from '../actions';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: '100vh',
    overflow: 'auto',
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing(2),
  },
});

const Layout = ({ classes, match }) => {
  const drawerOpen = useSelector(sideMenuStatusSelector);
  const dispatch = useDispatch();

  const toggleDrawerOpen = () => {
    dispatch(sideMenuToggle())
  }

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Header open={drawerOpen} onDrawerOpen={() => toggleDrawerOpen()} />

      <SideBar open={drawerOpen} onDrawerClose={() => toggleDrawerOpen()} />

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Route component={TopBreadcrumbs} />
        <Route exact path={`${match.path}sensors`} component={SensorListPage} />
        <Route exact path={`${match.path}sensors/:id/edit`} component={SensorEditPage} />
        <Route exact path={`${match.path}sensors/:id`} component={SensorDetailPage} />
        <Route exact path={`${match.path}sensors/:id/measurements`} component={SensorMeasurementsPage} />
        <Route exact path={`${match.path}sensors/:id/events`} component={SensorEventsPage} />
        <Route exact path={`${match.path}settings`}  component={SettingsPage}/>
        <Route exact path={match.path} component={Dashboard} />
      </main>

    </div>
  );
}


Layout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Layout);
