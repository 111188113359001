import React, { Component } from 'react';
import { compose } from 'redux'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import moment from 'moment';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import 'react-table/react-table.css';
import 'rc-tooltip/assets/bootstrap_white.css';

import OnlineIcon from './onlineIcon.jsx';
import SignalStrengthIcon from './signalStrengthIcon.jsx';
import USBBatteryIcon from './USBBatteryIcon.jsx';
import MeasurementList from './measurementList.jsx';
import { BATTERY_FILTERS, CONNECTIVITY_FILTERS, ALARM_FILTERS, TYPE_FILTER_ALL, setSensorSelected } from '../../actions';
import Constants from '../../common/constants';
import SensorFaultIcon from "./SensorFaultIcon";
import AlarmIcon from "./AlarmIcon";
import BatteryAlarmIcon from "./BatteryAlarmIcon";
import EditButtonIcon from "./EditButtonIcon";
import { Link } from 'react-router-dom';
import MeasurementsButtonIcon from './MeasurementsButtonIcon.jsx';
import EventButtonIcon from './EventButtonIcon.jsx';
import { BATTERY_LOW } from '../../indicatorsThresholds';
import { withTranslation } from 'react-i18next';

class SensorTable extends Component {

  constructor(props) {
    super(props);

    this.state={
      sensors: this.props.sensors,
      isStaff: this.props.isStaff,
    }

    this.filter = this.filter.bind(this);
    this.filterConnectivity = this.filterConnectivity.bind(this);
    this.filterBattery = this.filterBattery.bind(this);
    this.filterAlarm = this.filterAlarm.bind(this);
    this.filterType = this.filterType.bind(this);
    this.performSearch = this.performSearch.bind(this);
    this.filterRollup = this.filterRollup.bind(this);
    this.postProcessData = this.postProcessData.bind(this);
  }

  static getDerivedStateFromProps(newProps,prevState){
    if(newProps.isLoading === false && newProps.sensors !== prevState.sensors){
      return{
        sensors: newProps.sensors,
      };
    }
    return{
      prevState,
    };
  }

  filterConnectivity(sensor) {
    if (this.props.connectivityFilter === CONNECTIVITY_FILTERS.ONLINE)
      return sensor.is_online !== undefined && sensor.is_online !== null && sensor.is_online;

    if (this.props.connectivityFilter === CONNECTIVITY_FILTERS.OFFLINE)
      return sensor.is_online === null || sensor.is_online === undefined || !sensor.is_online;

    return true;
  }

  filterBattery(sensor) {
    if (this.props.batteryFilter === BATTERY_FILTERS.BATTERY_LOW)
      return sensor.status.latest_battery && sensor.status.latest_battery < BATTERY_LOW;

    if (this.props.batteryFilter === BATTERY_FILTERS.BATTERY_OK)
      return sensor.status.latest_battery && sensor.status.latest_battery >= BATTERY_LOW;

    return true;
  }

  filterAlarm(sensor) {
    const hasAlarms = sensor.status.has_alarm || sensor.status.has_prealarm || sensor.status.sensor_chip_state !== 0;
    if (this.props.alarmFilter === ALARM_FILTERS.NO_ALARM)
      return !hasAlarms;

    if (this.props.alarmFilter === ALARM_FILTERS.HAS_ALARM)
      return hasAlarms;

    return true;
  }

  filterType(sensor) {
    const typeFilter = this.props.typeFilter;

    if (typeFilter === TYPE_FILTER_ALL) return true;

    return sensor.sensor_type === typeFilter;
  }

  performSearch(sensor) {
    const pattern = _.lowerCase(this.props.searchPattern);

    if (pattern === undefined || pattern === null || pattern === '') return true;

    if (!this.props.isStaff) {
      return _.lowerCase(sensor.name).indexOf(pattern) >= 0
        || _.lowerCase(sensor.sensor_type).indexOf(pattern) >= 0;
    }

    return _.lowerCase(sensor.name).indexOf(pattern) >= 0
      || _.lowerCase(sensor.sensor_type).indexOf(pattern) >= 0
      || _.lowerCase(sensor.mac_address).indexOf(pattern) >= 0
      || _.lowerCase(sensor.belongs_to).indexOf(pattern) >= 0;
  }

  filterRollup(sensor) {
    return this.filterConnectivity(sensor)
      && this.filterBattery(sensor)
      && this.filterAlarm(sensor)
      && this.filterType(sensor)
      && this.performSearch(sensor);
  }

  filter(sensors) {
    return _.filter(sensors, this.filterRollup);
  }

  postProcessData(data) {
    return _.map(data, (sensor) => {
      sensor.latest_values = _.map(sensor.latest_values, (valueObject) => {
        if (valueObject.measurement_type === Constants.DISCOMFORT_INDEX_MEASUREMENT_TYPE)
          return Object.assign(
            {},
            valueObject,
            {
              value: Constants.getDIDescription(valueObject.value),
              units: '',
            });
        return valueObject;
      });

      return sensor;
    });
  }

  render() {
    // const { isStaff, sensors } = this.props;
    let data = this.filter(this.state.sensors.slice());
    // data = this.postProcessData(data);

    let columns = [{
      id: 'alarmStart',
      Header: <span><i className="md-alert-triangle"></i> </span>,
      sortable: false,
      accessor: d => ({
        'hasAlarms': d.status.has_alarm || d.status.has_prealarm || d.status.sensor_chip_state !== 0,
      }),
      maxWidth: 25,
      Cell: row => (
        <span>
          {row.value.hasAlarms && <FontAwesomeIcon icon={["far", "exclamation-triangle"]} />}
        </span>
      ),
    }, {
      id: 'name',
      Header: this.props.t('Name'),
      accessor: d => ({
        'name': d.name || d.id,
        'sensorRef': d.ref_id,
      }),
      minWidth: 140,
      sortMethod: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        // force null and undefined to the bottom
        a = (a === null || a === undefined) ? -Infinity : a;
        b = (b === null || b === undefined) ? -Infinity : b;
        // force any string values to lowercase
        a = a === 'string' ? a.toLowerCase() : a;
        b = b === 'string' ? b.toLowerCase() : b;
        // Return either 1 or -1 to indicate a sort priority
        if (a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }
        // returning 0, undefined or any falsey value will use subsequent sorts or the index as a tiebreaker
        return 0;
      },
      Cell: row => (
        <Link to={`/sensors/${row.value.sensorRef}`} onClick={()=>{this.props.onSensorSelected(row.value.sensorRef)}}>{row.value.name}</Link>
      ),
    }, {
      id: 'latest_Values',
      Header: this.props.t('Last measurements'),
      accessor: d => ({
        time: _.max(_.map(d.latest_values, val => val.measurement_time)),
        values: d.latest_values,
      }),
      sortable: false,
      minWidth: 160,
      Cell: row => (
        <>
          {row.value.time && <span> {moment(row.value.time).fromNow()}<br/></span>}
          {row.value.values && <MeasurementList values={row.value.values} />}
        </>
      ),
    }, {
      Header: this.props.t('Type'),
      accessor: 'sensor_type',
      minWidth: 140,
    }, {
      id: 'status',
      Header: this.props.t('Status'),
      sortable: false,
      accessor: d => ({
        isOnline: d.is_online,
        rssi: d.status.latest_rssi,
        usb: d.status.latest_usb,
        battery: d.status.latest_battery,
      }),
      minWidth: 70,
      Cell: row => (
        <span className="distribute">
          <OnlineIcon isOnline={row.value.isOnline} />
          <SignalStrengthIcon rssi={row.value.rssi} />
          <USBBatteryIcon isUSB={row.value.usb} battery={row.value.battery} />
        </span>
      ),
    }, {
      id: 'alarms',
      Header: this.props.t('Alarms'),
      sortable: false,
      accessor: d => ({
        current_status: d.status.sensor_chip_state,
        alarm: d.status.has_alarm || d.status.has_prealarm,
        battery_alarm: d.status.latest_battery && d.status.latest_battery < BATTERY_LOW,
        bootloader_failed: d.bootloader_failed,
      }),
      maxWidth: 80,
      Cell: row => (
        <span>
          <SensorFaultIcon current_status={row.value.current_status} />
          <AlarmIcon alarm={row.value.alarm} />
          <BatteryAlarmIcon battery_alarm={row.value.battery_alarm} />
        </span>
      ),
    }, {
      id: 'actions',
      Header: this.props.t('Actions'),
      accessor: "ref_id",
      sortable: false,
      minWidth: 100,
      Cell: row =>
        (
          <span className="distribute">
            <EditButtonIcon sensor_id={row.value} />
            <MeasurementsButtonIcon sensor_id={row.value} />
            <EventButtonIcon sensor_id={row.value} />
          </span>
        )
    }];

    if (this.state.isStaff) {
      const staffColumns = [{
        Header: 'Mac Address',
        accessor: 'mac_address',
        minWidth: 140,
      }, {
        Header: 'Belongs to',
        accessor: 'account',
      }];

      columns.splice(6, 0, staffColumns[0], staffColumns[1]);
    }

    return <ReactTable
      data={data}
      columns={columns}
      defaultSorted={[
        {
          id: 'name',
          desc: false,
        },
      ]}
      className="-striped -highlight"
      nextText={this.props.t("Next")}
      previousText={this.props.t("Previous")}
      pageText={this.props.t("Page")}
      rowsText={this.props.t("rows")}
      ofText={this.props.t("of")}
    />;
  }
}

SensorTable.propTypes = {
  isStaff: PropTypes.bool,
  sensors: PropTypes.array,
  connectivityFilter: PropTypes.string.isRequired,
  batteryFilter: PropTypes.string.isRequired,
  alarmFilter: PropTypes.string.isRequired,
  typeFilter: PropTypes.string.isRequired,
  searchPattern: PropTypes.string.isRequired,
  onSensorSelected: PropTypes.func,
};

const mapStateToProps = state => ({
  isStaff: state.user.isStaff,
  sensors: state.sensors.sensors,
  isLoading : state.sensors.isLoading,
  connectivityFilter: state.sensors.connectivityFilter,
  batteryFilter: state.sensors.batteryFilter,
  alarmFilter: state.sensors.alarmFilter,
  typeFilter: state.sensors.typeFilter,
  searchPattern: state.sensors.searchPattern,
});

const mapDispatchToProps = dispatch => {
  return {
    onSensorSelected: sensorRef => dispatch(setSensorSelected(sensorRef)),
  }
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(SensorTable);