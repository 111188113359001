import React, { useEffect } from 'react';
import ReactTable from 'react-table';
import moment from 'moment';
import { clearMeasurements } from '../../actions';
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next'
import Constants from '../../common/constants'
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme =>({
  discomfort:{
    color: "#0000ff"
  }
}))

const MeasurementsTable = ({ measurements, measurement_names }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  useEffect(() => {
    return () => {
      dispatch(clearMeasurements());
    }
  })
  const columns = [
    {
      id: 'DateTime',
      Header: t('Record Date & Time'),
      accessor: 'time',
      Cell: row => (
        <>{moment.unix(row.value / 1000).format("DD/MMM/YYYY HH:mm:ss")}</>
      )
    },
  ]

  const MeasurementValue = ({value,name})=>{
    if(name !== "discomfort")
      return <>{value}</>
    return <div style={{color:Constants.MapDIColors[value]}}>{value}</div>
  }

  const names = measurement_names;
  names.forEach(name => {
    columns.push({
      id: name,
      Header: t(name),
      accessor: name,
      Cell: (row)=> {
        return <MeasurementValue value={row.row[name]} name={name}/>
      }
    })
  });

  return (
    <ReactTable
      data={measurements}
      columns={columns}
      defaultSorted={[
        {
          id:"DateΤime",
          desc: true
        }

      ]}
      className="-striped -highlight"
      nextText={t("Next")}
      previousText={t("Previous")}
      pageText={t("Page")}
      rowsText={t("rows")}
      ofText={t("of")}
    />
  )
}

export default MeasurementsTable;