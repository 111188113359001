import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import List from '@material-ui/core/List';

import * as actions from '../../actions';
import FilterLink from './filterLink.jsx';

const mapDispatchToProps = dispatch => {
  return {
    onFilterClick: name => {
      dispatch(actions.setTypeFilter(name));
    },
  };
};

const mapStateToProps = state => ({
  currentFilter: state.sensors.typeFilter,
});

const AlarmFilterLink = connect(mapStateToProps, mapDispatchToProps)(FilterLink);

const TypeFilters = ({types}) => 
  <List>
    {_.map(types, (type, i) => <AlarmFilterLink key={i} filterName={type} />)}
  </List>

TypeFilters.propTypes = {
  types: PropTypes.array.isRequired,
};

const mapStateToPropsFilters = state => ({
  types: state.sensors.sensor_types,
});

export default connect(mapStateToPropsFilters, null)(TypeFilters);