import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SensorList from '../components/sensorList/sensorlist';
import { useTranslation } from 'react-i18next';
import { clearSelectedSensor} from "../actions";
import { selectedSensor } from '../selectors/sensorSelectors';
import { useDispatch, useSelector } from 'react-redux';

const styles = theme => ({
  tableContainer: {
    height: 320,
  },
});

const SensorListPage = ({ classes }) => {
  const { t } = useTranslation();

  const selectedS = useSelector(selectedSensor);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedS !== null) {
      dispatch(clearSelectedSensor());
    }
  }, [selectedS,dispatch]
  );

  return (
    <>
      <Typography variant="h4" gutterBottom component="h2">
        {t('Sensors')}
      </Typography>
      <SensorList></SensorList>
    </>
  )
}

SensorListPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SensorListPage);
