import React from 'react';
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useSelector } from "react-redux";
import { eventsSelector, eventsLoadingSelector } from '../../selectors/eventsSelectors'
import EventsTable from './EventsTable'
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useTranslation} from "react-i18next";
import { exportToCSV } from '../../common/CSVUtils';
const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "400px",
  },
  chartContainer: {
    display: "flex",
  },
  loading: {
    margin: "auto",
  },
  configurator: {
    padding: theme.spacing(2)
  }
}));

const EventsPanel = () => {
  const isLoading = useSelector(eventsLoadingSelector);
  const classes = useStyles();
  const {t} = useTranslation();
  const events = useSelector(eventsSelector);
  const emptyEvents = events == null || events.length === 0;
  const error = useSelector(eventsSelector);

  const render_events_panel = () => {
    if (isLoading)
      return <CircularProgress className={classes.loading} />;
    else if (!isLoading && emptyEvents)
      return <Typography className={classes.loading}>{t("No events loaded")}</Typography>
    else if (!isLoading && emptyEvents && error)
      return <Typography className={classes.loading}>{t(error.error ? error.error : "Error while loading events")}</Typography>;
    else if (!isLoading && !emptyEvents)
      return <EventsTable events={events} />
  }

  const exportCSV = (events) => {
    let csvURL = window.URL.createObjectURL(exportToCSV(events));
    let tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'events.csv');
    document.body.appendChild(tempLink);
    tempLink.click();
  }

  return (
    <Grid className={classes.configurator}>
      <Button disabled={emptyEvents} variant="contained" color="primary" className={classes.button} size="large" fullWidth onClick={() => { exportCSV(events) }}>
        <FontAwesomeIcon icon={["far", "download"]} />
        &nbsp;{t('Export to CSV')}
      </Button>
      {render_events_panel()}
    </Grid>
  )
}

export default EventsPanel;